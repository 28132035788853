import React from "react";

function ArabicDocx() {
  const styles = {
    h3Tag: {
      fontSize: "21px",
    },
    heading: {
      textAlign: "center",
      margin: "5px 0",
      fontSize: "15px",
    },
    container: {
      fontFamily: "Arial, sans-serif",
      margin: "auto",
    },
    guidelines: {
      marginTop: "20px",
      fontWeight: "500",
      fontSize: "20px",
    },
    ul: {
      listStyleType: "none",
      paddingLeft: 0,
    },
    checkboxContainer: {
      input: {
        transform: "scale(1.5)",
      },
    },
    checkboxLabel: {
      alignItems: "center",
      marginLeft: "10px",
    },
  };

  return (
    <div style={styles.container}>
      <p style={styles.heading}>المبادئ التوجيهية للوالدين</p>
      <p style={styles.heading}> لخدمات EI مع</p>
      <p style={styles.heading}>العلاج مساعد ذ م م</p>

      <div style={styles.guidelines}>
        <p>
          1. بشكل عام EI مرحبا بكم في العلاج مساعد ! نحن سعداء بمحاولة مساعدتك
          في أي احتياجات أو مخاوف ، سواء مع مع مزود الخدمة الخاص بك. رقم هاتف TA
          هو 973-471-3046. موقعنا هو www.therapyassociates.com ، والبريد
          الإلكتروني لدينا هو casemanagement@therapyassociates.com.{" "}
        </p>

        <p>
          2. نموذج التدريب العائلي وتكرار الخدمة: إن الهدف الأساسي وفلسفة نظام
          NJ Early Intervention System هو تمكين وتمكين أنت - الوالدين - لتسهيل
          نمو وتطور أطفالك. يتم إرسال الممارسين للعائلات في المقام الأول لتثقيفك
          ومقدمي الرعاية الآخرين بكيفية تعزيز هذا التطور. لذلك يتم تقديم الخدمات
          في البيئة الطبيعية لطفلك باستخدام ألعاب الأطفال والمناطق المحيطة.
        </p>

        <p>
          3. السلامة والنظافة: يرجى العلم بأن TA تأخذ سلامة وصحة العائلات
          والممارسين على محمل الجد. يرجى العلم بالإجراءات التالية التي وضعناها
          لتعزيز السلامة والنظافة للجميع:
        </p>
        <ul style={styles.ul}>
          <li>
            <label style={styles.checkboxContainer}>
              <span style={styles.checkboxLabel}>
                {" "}
                يرجى التأكد من أن المنزل أو مجال رعاية الأطفال آمن ونظيف.
                الممارس غير ملزم بتقديم الخدمات في بيئة يعتبرها غير آمنة أو غير
                صحية.
              </span>
            </label>
          </li>
          <li>
            <label style={styles.checkboxContainer}>
              <span style={styles.checkboxLabel}>
                لا يُسمح للطبيب بمعاملة الطفل ما لم يكن هناك شخص بالغ آخر حاضر
                خلال الدورة بأكملها وهو مستيقظ ومتوفر. قد لا يترك الوالد / مقدم
                الرعاية المنزل (أو مكان العلاج) لأي سبب خلال الجلسة.
              </span>
            </label>
          </li>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              إذا كان الممارس الذي يخدم عائلة في المنزل مع الطفل فقط وشخص بالغ
              من الجنس الآخر ، يُطلب من الممارس ترك الباب الأمامي مفتوحًا
              قليلاً. هذا هو لحماية الجميع من أي شيء غير لائق يحدث. يرجى السماح
              للممارس باتباع هذه التعليمات.
            </span>

            <div className="html2pdf__page-break"></div>

            <p>
              لا يجوز للممارس علاج الطفل إذا كان الطفل مريضًا ، ولا يجوز خدمة
              الطفل إذا كان الممارس مريضًا. بالإضافة إلى ذلك ، قد لا يعامل
              الممارس الطفل إذا كان شقيق الطفل أو أي شخص آخر في المنزل مصابًا
              بمرض معدٍ.
            </p>
          </label>
          <li>
            <label style={styles.checkboxContainer}>
              <span style={styles.checkboxLabel}>
                {" "}
                ﻳﻜﻮن اﻟﻮاﻟﺪ ﻣﺴﺆوﻻً أن ﻳﺨﺒﺮ اﻟﻤﻤﺎرس ﻋﻨﺪﻣﺎ ﻳﻜﻮن اﻟﻄﻔﻞ ﻣﺮﻳﻀًﺎ أو
                ﻋﻨﺪﻣﺎ ﻳﻜﻮن ﺷﺨﺺ ﺁﺧﺮ ﻓﻲ اﻟﻤﻨﺰل ﻣﻌﺪﻳًﺎ.
              </span>
            </label>
          </li>
          <li>
            <label style={styles.checkboxContainer}>
              <span style={styles.checkboxLabel}>
                {" "}
                ﻳﻜﻮن اﻟﻮاﻟﺪ ﻣﺴﺆوﻻً أن ﻳﺨﺒﺮ اﻟﻤﻤﺎرس ﻋﻨﺪﻣﺎ ﻳﻜﻮن هﻨﺎك ﻋﺪوى ﻋﻠﻰ
                اﻟﻄﻔﻞ أو ﻓﻲ اﻟﻤﻨﺰل ﻣﺜﻞ اﻟﺒﻘﻖ أو اﻟﻘﻤﻞ. المعالج لديه سلطة تقديرية
                حول موعد العلاج في هذه الظروف. قد يُطلب من الطبيب تصريح طبي
                لاستئناف الخدمات ، حسب الحالة.
              </span>
            </label>
          </li>
          <li>
            <label style={styles.checkboxContainer}>
              <span style={styles.checkboxLabel}>
                عندما يحين وقت قيام الممارس بكتابة مذكرة التقدم ، يجب عليه /
                عليها تسليم المسؤولية عن مراقبة الطفل إلى الوالد أو جليسة
                الأطفال ، ومن المفهوم أن الوالد أو جليسة الأطفال ستضمن الإشراف
                المناسب عند هذه النقطة. لا يستطيع الممارس مشاهدة الطفل وكتابة
                الملاحظة في نفس الوقت.{" "}
              </span>
            </label>
          </li>
          <li>
            <label style={styles.checkboxContainer}>
              <span style={styles.checkboxLabel}>
                ا كان الطفل قد خضع لعملية جراحية أو مرض خطير ، قد يتم طلب مذكرة
                تخليص طبي قبل استئناف الخدمات.{" "}
              </span>
            </label>
          </li>
        </ul>
        <p>
          4. الجدولة: يجب على كل ممارس عمل جدول زمني ثابت معك. يجب ألا تتغير
          أيام الخدمة والأوقات من أسبوع لآخر. إذا لم يتم الالتزام بجدول زمني
          ثابت ، فقد توقف TA عن تقديم الخدمات.{" "}
        </p>

        <h3 style={styles.h3Tag}>5. سياسة الحضور: </h3>
        <ul style={styles.ul}>
          <li>
            <label style={styles.checkboxContainer}>
              <span style={styles.checkboxLabel}>
                {" "}
                يجب أن يكون الطفل حاضراً ومتاحاً لبدء العلاج في وقت جدولة
                الجلسة. هذا يعني أن الوالد قادر على سماع وصول المعالج والإجابة
                على الباب ، الطفل مستيقظ ، مطعم ، غير مشغول بعلاج آخر أو نشاط
                آخر ، ومع حفاضة نظيفة.{" "}
              </span>
              <p>
                {" "}
                أي ﺣﺎﻻت ﺗﺄﺧﻴﺮ ﻣﻦ اﻟﻮﻗﺖ اﻟﺬي ﻳﺼﻞ ﻓﻴﻪ اﻟﻤﻌﺎﻟﺞ ﻓﻲ اﻟﻮﻗﺖ اﻟﻤﻘﺮر ،
                ﻳﺘﻢ ﺧﺼﻤﻪ ﻣﻦ اﻟﻮﻗﺖ اﻟﻤﺘﻮﻓﺮ ﻟﺨﺪﻣﺔ اﻟﻄﻔﻞ ﺑﺪون وﻗﺖ ﻣﻜﺴﻲ.
              </p>
            </label>
          </li>
          <li>
            <label style={styles.checkboxContainer}>
              <span style={styles.checkboxLabel}>
                {" "}
                إذا لم يكن الطفل متاحًا على الإطلاق (أي النوم أو المرضى أو عدم
                الذهاب إلى المنزل أو الرعاية النهارية) ولم يتم إجراء أي اتصال
                لإلغاء الجلسة ، يتم احتساب الجلسة على أنها "عدم حضور". قد ينتظر
                المعالج حتى 15 دقيقة للعائلة ، إذا كانت قادرة ، ثم تغادر المنزل
                أو الرعاية النهارية. لن يتم منح أي مكياج "للعروض".
              </span>
            </label>
          </li>

          <div className="html2pdf__page-break"></div>

          <li>
            <label style={styles.checkboxContainer}>
              <span style={styles.checkboxLabel}>
                {" "}
                إذا كان الطفل / العائلة لا يظهر ثلاث مرات ، فإن TA قد يوقف
                الخدمات تمامًا دون أي تعويض مسموح به.
              </span>
            </label>
          </li>
        </ul>
        <h3 style={styles.h3Tag}>6. إلغاء الجلسة: </h3>
        <ul style={styles.ul}>
          <li>
            <label style={styles.checkboxContainer}>
              <span style={styles.checkboxLabel}>
                {" "}
                إذا كانت الأسرة بحاجة إلى إلغاء الخدمات ، يجب أن يتم إجراء
                المكالمة قبل 24 ساعة من العلاج.
              </span>
              <p>
                is يتم اعتبار الإلغاء في صباح الجلسة المقررة "عدم حضور". كما هو
                مذكور أعلاه ، لا يتم إعطاء أي تعويض لأي عروض ، وإذا لم يكن الطفل
                / الأسرة معرضًا ثلاث مرات ، فقد توقف TA الخدمات تمامًا دون أي
                ميك أب المسموح بها.
              </p>
              <p>
                {" "}
                قد يؤدي الإلغاء المتعدد والمستمر للعلاج إلى وقف الخدمات لأن
                المعالج حجز الوقت المخصص لطفلك ، ولا يمكنه تحديد موعد لأي شخص
                آخر في ذلك الوقت.
              </p>
            </label>
          </li>
        </ul>
        <p>
          7. إلغاء الخدمات: إذا لم تعد ترغب في مواصلة جلسات EI ، فيُرجى إخبار
          المعالج أو TA أو منسق الخدمة.{" "}
        </p>
        <h3 style={styles.h3Tag}>
          8. تتمثل سياسة ولاية نيوجيرسي بشأن توفيرتعويض للجلسات التي لم يتم
          الوفاء بها ، في ما يلي:
        </h3>
        <ul style={styles.ul}>
          <li>
            <label style={styles.checkboxContainer}>
              <span style={styles.checkboxLabel}>
                {" "}
                الممارس له الحق في التغيب عن الأعياد القانونية والدينية دون
                الحاجة إلى تقديم تعويض. هذه التواريخ مكتوبة على التقويم السنوي
                لـ TA على موقع الويب. (www.therapyassociates.com) ، أو في يوم
                آخر إذا احتفلوا بعطلة دينية مختلفة. يجب على الممارس إبلاغ
                العائلة عندما تخطط لملاحظة عطلة قانونية أو دينية.{" "}
              </span>
              <p>
                is لا يسمح للممارس / الوكالة بتقديم جلسة تعويض في حالة الإلغاء
                بسبب سوء الأحوال الجوية ، أو عندما يلغي الوالد الجلسة لأي سبب.
                عندما يكون موقع الخدمة في الرعاية النهارية أو جليسة الأطفال ، لا
                يكون الممارس / الوكالة ملزمين بتقديم جلسات تعويض عندما يتم إغلاق
                الرعاية النهارية أو جليسة الأطفال أو إلغائها. في هذه الحالة ،
                إذا كان لديه / لديها الوقت ، قد يقدم الممارس جلسة مجدولة حتى
                نهاية الأسبوع (السبت) ولكن ليس لاحقًا.
              </p>
            </label>
          </li>

          <div className="html2pdf__page-break"></div>

          <li>
            <label style={styles.checkboxContainer}>
              <span style={styles.checkboxLabel}>
                {" "}
                يحق ممارس إلغاء الدورات في بعض الأحيان. يجب على الممارس تقديم
                جلسة تعويض لهذه الدورة. إذا كانت تواريخ / أوقات ي تعويض
              </span>
              <p>
                {" "}
                المقدمة لا تفي بأي من التوافر ، فيرجى إخبارنا بذلك. يمكنك أيضًا
                التحدث إلى منسق خدمتك لطلب خدمات تعويضية.
              </p>
              <p>
                {" "}
                قد لا يتم إجراء الجلسات التي فاتت قبل اجتماع مراجعة IFSP بعد بدء
                صفحة الخدمة الجديدة. في هذه الحالة ، تحدث إلى منسق خدمتك حول طلب
                الخدمات التعويضية للجلسات التي لم يتم الرد عليها.
              </p>
            </label>
          </li>
        </ul>
        <h3 style={styles.h3Tag}>9. موقع الخدمات: </h3>
        <p>IFSP على صفحة خدمة الانضباط يجب أن تحدث الخدمات في الموقع المحدد </p>
        <ul style={styles.ul}>
          <li>
            <label style={styles.checkboxContainer}>
              <span style={styles.checkboxLabel}>
                {" "}
                في بعض الأحيان ، إذا تم تحديد الخدمة التي سيتم إجراؤها في المنزل
                ، يمكن للممارس رؤية الطفل في الرعاية النهارية. وبالمثل ، إذا
                حددت صفحة الخدمة موقع الرعاية النهارية ، فيجوز للممارس إجراء
                جلسة في المنزل. ومع ذلك ، إذا كان التغيير في الموقع سيكون طويل
                الأجل ، فيجب كتابة صفحة خدمة جديدة لتعكس الموقع الجديد.
              </span>
            </label>
          </li>
        </ul>
        <h3 style={styles.h3Tag}>10. حكم الجلسة:</h3>
        <p> تسمح بذلك.IFSP قد تستمر كل جلسة ما دامت صفحة خدمة .</p>
        <ul style={styles.ul}>
          <li>
            <label style={styles.checkboxContainer}>
              <span style={styles.checkboxLabel}>
                {" "}
                تتألف الجلسة من التفاعل مع الطفل ، ومناقشة مع الوالد / مقدم
                الرعاية ، والحصول على توقيع الوالد / الوصي ، وكتابة الملاحظات
                السريرية (انظر أدناه).{" "}
              </span>
            </label>
          </li>
          <li>
            <label style={styles.checkboxContainer}>
              <span style={styles.checkboxLabel}>
                ويتم تضمينها في وقت مدة الخدمة EI تعتبر المناقشة مع أفراد
                العائلة أو مقدمي الرعاية جزءًا لا يتجزأ من جلسة المعالجون
                بفاتورة الوقت المستغرق في المناقشة خلال الجلسة سيقوم.
              </span>
              <p>
                is يتم تضمين الوقت المستغرق في توثيق خدمة اليوم والحصول على
                توقيع في سجل الحضور في مدة الخدمة. لا يوجد مقدار معين من الوقت
                يفترض أن يتم إنفاقه في كتابة الملاحظات ؛ بدلا من ذلك يعتمد على
                الممارس الفرد والجلسة.
              </p>
            </label>
          </li>

          <div className="html2pdf__page-break"></div>

          <li>
            <label style={styles.checkboxContainer}>
              <span style={styles.checkboxLabel}>
                {" "}
                لا يجوز لأي من الوالدين أو الممارسين تغيير تواتر IFSP و / أو مدة
                الجلسات ، حتى لو كان ذلك أفضل لجدول الأسرة (على سبيل المثال
                اتخاذ قرار بالقيام بساعتين من العلاج في وقت واحد بدلاً من القيام
                بساعة واحدة مرتين في الأسبوع). إذا لم يكن برنامج الخدمة المدنية
                الدولية يفي باحتياجات الأسرة ، فينبغي على الأسرة الاتصال بـ TA
                أو منسق الخدمة لتغيير الأوراق.{" "}
              </span>
              <p>
                is لا يُسمح للممارس بالخدمة بعد تاريخ انتهاء صفحة خدمة IFSP
                للطفل. ﯾﺟب ﮐﺗﺎﺑﺔ ﺧطﺔ IFSP ﻣﺣدﺛﺔ ﻓﻲ اﺟﺗﻣﺎع اﺳﺗﻌراﺿﻲ ﻟﻟﺧدﻣﺎت
                ﻟﻟﻣﺗﺎﺑﻌﺔ.
              </p>
              <p>
                {" "}
                قد لا يتم توفير الخدمات في عيد ميلاد الطفل الثالث وما بعده.
              </p>
              <p>
                {" "}
                قد لا يتم توفير الخدمات في حين تم تعليق حساب العائلة حتى يتم
                إعادة الحساب ، ويتم كتابة صفحات الخدمات الجديدة وتوقيعها من قِبل
                الشركة الأم.
              </p>
            </label>
          </li>
        </ul>
        <h3 style={styles.h3Tag}>11. الوثائق: </h3>
        <p>
          {" "}
          نموذج التحقق من مقابلة الخدمة (SEVF). بعد كل جلسة سيقدم لك الممارس (أو
          مقدم رعاية الطفل) SEVF ، وهو سجل الحضور ، للتوقيع. قد يكون هذا إما
          ورقة أو إلكترونية ، من موقع TA. يجب أن يملأ الممارس في التاريخ والموقع
          ووقت البدء ووقت الانتهاء قبل طلب التوقيع. سيتم حساب الساعات الإجمالية
          وإضافتها في وقت لاحق.
        </p>
        <ul style={styles.ul}>
          <li>
            <label style={styles.checkboxContainer}>
              <span style={styles.checkboxLabel}>
                {" "}
                التوقيع على الاستمارة شهادة وشهادة على أن الممارس قام بالفعل
                بتوفير الخدمة المطلوبة في التواريخ والأوقات المحددة.{" "}
              </span>
            </label>
          </li>
          <li>
            <label style={styles.checkboxContainer}>
              <span style={styles.checkboxLabel}>
                إذا تم ارتكاب خطأ في نموذج الورقة ، فسوف يطلب منك الممارس البدء
                في أي عمليات تقاطع أو تصحيحات.
              </span>
              <p>
                يجوز للممارس إصدار فواتير فقط مقابل ما يقضيه من وقت في الجلسة.
                إذا اختصرت الجلسة بسبب تأخر أو سبب آخر ، فإن الوقت المستغرق في
                الفاتورة يجب أن يعكس فقط الوقت المستغرق في الجلسة (بما في ذلك
                وقت تدوين الملاحظات ووقت المناقشة).
              </p>
            </label>
          </li>

          <div className="html2pdf__page-break"></div>

          <li>
            <label style={styles.checkboxContainer}>
              <span style={styles.checkboxLabel}>
                {" "}
                سيقوم الممارس أيضا بكتابة ملاحظة سريرية على البوابة الإلكترونية.
                يجب أن تحتوي الملاحظة السريرية على ملخص للأنشطة المنجزة وتوصيات
                للاستراتيجيات والأنشطة التي يمكنك استخدامها في المنزل مع طفلك.
                سيتم الانتهاء من هذه المذكرة خلال وقت جلسة IFSP.{" "}
              </span>
            </label>
          </li>
          <li>
            <label style={styles.checkboxContainer}>
              <span style={styles.checkboxLabel}>
                {" "}
                إذا لم يقدم الممارس شهادة SEVF للتوقيع ، أو إذا كانت لديك أية
                أسئلة أخرى تتعلق بكيفية توثيق الجلسات بشكل صحيح ، فيرجى الاتصال
                بمكتب المساعدة الفنية.
              </span>
            </label>
          </li>
        </ul>
        <p>
          نحن نتطلع إلى العمل معك ، ونأمل أن يكون لك ولطفلك علاقة تعليمية ومثمرة
          مع ممارسينا!
        </p>
      </div>
    </div>
  );
}

export default ArabicDocx;
