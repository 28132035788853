import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function PolicyProcedure() {
  const navigate = useNavigate();

  const { childId, childContactId } = useParams();

  const handleButtonClick = (buttonText) => {
    navigate("/policy-procedure-sign", {
      state: {
        selectedLanguage: buttonText,
        childId: childId,
        childContactId: childContactId
      },
    });
  };

  return (
    <>
      <div className="container custom-container h-100">
        <div className="d-flex justify-content-center align-items-center flex-column h-100">
          <h4>Select a langugae</h4>
          <div className="contactBoxStyle p-4">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleButtonClick("English")}
            >
              English
            </button>
            <button
              type="button"
              className="btn btn-info text-white ms-2"
              onClick={() => handleButtonClick("Spanish")}
            >
              Spanish
            </button>
            <button
              type="button"
              className="btn btn-success ms-2"
              onClick={() => handleButtonClick("Arabic")}
            >
              Arabic
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PolicyProcedure;
