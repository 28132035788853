import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";

function BillingConfirmed() {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className="container custom-container my-5">
        <div className="text-center px-4">
          <h4>
            <strong>Billing Confirmed!</strong>
          </h4>
          <p className="my-4 mx-auto">
            Payments are made on the 20th of each month for the previous month,
            if billing was submitted on time. If you confirmed billing for
            months before the last month, you can expect payment within 7 days.
          </p>
          <p className="my-4 mx-auto">
            Please reach out to your case manager with any questions or to
            request an update on a payment.
          </p>
          <div>
            <button
              className="p-3 text-white"
              style={{ background: "#0A1E4FE5", borderRadius:'5px', border:'none', outline:'none' }}
              onClick={()=>{navigate('/')}}
            >
              Return to Home Page
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default BillingConfirmed;
