import React, { useState } from "react";

function EnglishDocx() {
  const styles = {
    h3Tag: {
      fontSize: "21px",
    },
    heading: {
      textAlign: "center",
      margin: "5px 0",
      fontSize: "15px",
    },
    container: {
      fontFamily: "Arial, sans-serif",
      margin: "auto",
    },
    guidelines: {
      marginTop: "20px",
      fontWeight: "500",
    },
    ul: {
      listStyleType: "none",
      paddingLeft: 0,
    },
    checkboxContainer: {
      input: {
        transform: "scale(1.5)",
        marginRight: "5px",
      },
    },
    checkboxLabel: {
      alignItems: "center",
      marginLeft: "10px",
    },
  };

  return (
    <div style={styles.container}>
      <p style={styles.heading}>Guidelines for Parents</p>
      <p style={styles.heading}> For their EI Services with</p>
      <p style={styles.heading}>Therapy Associates LLC</p>

      <div style={styles.guidelines}>
        <p>
          1. <b>Welcome to Therapy Associates!</b> We are happy to try to help
          you with any needs or concerns, either with EI in general of with your
          provider. TA’s phone number is 973-471-3046. Our website is
          www.therapyassociates.com, and our email is
          casemanagement@therapyassociates.com.{" "}
        </p>

        <p>
          2. <b>Family Training Model and Frequency of Service: </b> The NJ
          Early Intervention System’s overriding goal and philosophy is to
          empower and enable YOU –the parents-- to facilitate the growth and
          development of your children. Practitioners are sent to families
          primarily to educate you and your other caregivers how to promote this
          development. Therefore services are providpxed in your child’s natural
          environment, using the child’s toys and surroundings.
        </p>

        <p>
          3. <b>Safety and Hygiene:</b>Please be aware that TA takes the safety
          and health of families and practitioners very seriously. Please be
          aware of the following procedures we have instituted to further safety
          and hygiene for all:{" "}
        </p>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Please make sure that the home or babysitting premises is safe and
              clean. Practitioner is not obligated to provide services in an
              environment that he/she deems unsafe or unhealthy.
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Practitioner is not allowed to treat a child unless there is
              another adult present during the entire session who is awake and
              available. Parent/caregiver may not leave the home (or place of
              therapy) for any reason during a session.
            </span>
          </label>
        </p>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              If a practitioner servicing a family is in the home with only the
              child and an adult of the opposite sex, the practitioner is
              instructed to leave the front door open a little. This is to
              protect everyone from anything improper happening. Please allow
              the practitioner to follow this instruction.
            </span>
          </label>
        </p>

        <div className="html2pdf__page-break"></div>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              A practitioner may not treat a child if the child is sick, nor may
              a child be serviced if the practitioner is sick. Additionally, a
              practitioner may not treat a child if the child’s sibling or
              another person in the home has a contagious illness.
            </span>
          </label>
        </p>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              The parent is responsible to let the practitioner know when a
              child is sick or when someone else in the home is contagious.{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              The parent is responsible to let the practitioner know when there
              is a contagion on the child or in the home such as bedbugs or
              lice. Therapist has discretion about when to treat in these
              circumstances. A letter of medical clearance may be required to
              resume services, depending on the situation.{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              When it is time for the practitioner to write the progress note,
              s/he must hand over responsibility for watching the child to the
              parent or babysitter, and it is understood that the parent or
              babysitter will ensure proper supervision at that point.
              Practitioner cannot watch the child and write the note at the same
              time.{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              If a child has had surgery or a serious illness, a note of medical
              clearance may be requested prior to the resumption of services.{" "}
            </span>
          </label>
        </p>

        <p>
          <b>4. Scheduling:</b>Every practitioner should work out a consistent
          schedule with you. Service days and times should not change from week
          to week. If a consistent schedule is not adhered to, TA may cease
          services.{" "}
        </p>

        <h3 style={styles.h3Tag}>5. Attendance Policy: </h3>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              The child must be <b>present and available</b> to start therapy at
              the time that the session is scheduled. This means that the parent
              is able to hear the arrival of the therapist and answer the door,
              the child is awake, fed, not occupied with another therapy or
              activity, and with a clean diaper.{" "}
            </span>
          </label>
        </p>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Any delays from the time the therapist arrives at the scheduled
              time is deducted from the time available for servicing the child
              with no makeup time owed.
            </span>
          </label>
        </p>

        <div className="html2pdf__page-break"></div>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              If a child is not available at all (i.e. sleeping, sick, or not
              home or at daycare), and no communication to cancel the session
              has been made, the session is counted as a “no show.” Therapist
              may wait up to 15 minutes for the family to come, if she is able,
              and will then leave the home or daycare. No makeups will be given
              for “no shows.”{" "}
            </span>
          </label>
        </p>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              If a child/family is a no show three times TA may stop services
              altogether with no makeups allowed.
            </span>
          </label>
        </p>

        <h3 style={styles.h3Tag}>6. Cancellation of a session: </h3>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              If a family needs to cancel services, a call must be placed 24
              hours in advance to the therapist.
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              A cancellation on the morning of a scheduled session is counted as
              a “no show.” As above, no makeups are given for no shows, and if a
              child/family is a no show three times TA may stop services
              altogether with no makeups allowed.
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Multiple and continuous cancellations of a therapy may trigger
              cessation of services since a therapist has reserved the time slot
              for your child, and cannot schedule anyone else at that time.
            </span>
          </label>
        </p>

        <p>
          <b>7. Cancelling Services: </b>If you no longer want to continue EI
          sessions please let either your therapist, TA, or your service
          coordinator know.{" "}
        </p>
        <h3 style={styles.h3Tag}>
          8. State of New Jersey policy on providing makeups for missed EI
          sessions is as follows:{" "}
        </h3>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              TPractitioner has the right to be absent on legal and religious
              holidays without having to offer makeups. These dates are written
              on TA’s yearly calendar found on the website.
              (www.therapyassociates.com), or on a different day if they
              celebrate a different religious holiday. Practitioner should let
              family know when she plans on observing a legal or religious
              holiday. .{" "}
            </span>
          </label>
        </p>

        <div className="html2pdf__page-break"></div>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Practitioner/agency is not allowed to offer a makeup session in
              the event of cancellation due to an extreme weather situation, or
              when the parent cancels a session for any reason. When the
              location of service is at a daycare or babysitter,
              practitioner/agency is not obligated to offer makeup sessions when
              the daycare or babysitter is closed or cancels. In this situation,
              if he/she has the time, the practitioner may offer a re-scheduled
              session until the end of the week (Saturday) but not later.
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              IPractitioner is entitled to cancel sessions on occasion. A
              practitioner should offer a makeup session for that session. If
              the offered makeup dates/times do not meet any of your
              availability, please let us know. You can also speak to your
              service coordinator to request compensatory services.
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Sessions missed prior to an IFSP review meeting may not be made up
              after the new service page starts. In this situation, speak to
              your service coordinator about requesting compensatory services
              for the missed sessions.
            </span>
          </label>
        </p>

        <h3 style={styles.h3Tag}>9. Location of Services: </h3>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Services must occur in the location specified in the IFSP on that
              discipline’s service page.
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              On occasion, if the service is specified to be conducted in the
              home the practitioner may see the child at a daycare. Similarly,
              if the service page specifies the location as daycare the
              practitioner may do a session at home. However, if the change in
              location will be long-term, then a new service page must be
              written to reflect the new location.
            </span>
          </label>
        </p>

        <h3 style={styles.h3Tag}>10. Session provision: </h3>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Each session may last only as long as the IFSP’s service page
              allows.{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              The session is comprised of interaction with the child, discussion
              with the parent/caregiver, getting a parent/guardian signature,
              and writing the clinical notes (see below).
            </span>
          </label>
        </p>

        <div className="html2pdf__page-break"></div>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Discussion with family members or caregivers is an integral part
              of the EI session and is included in the service duration time.
              Therapists will bill for time spent in discussion during the
              session.{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Time spent documenting the day’s service and obtaining a signature
              on the attendance log is included in the service duration time.
              There is no set amount of time that is supposed to be spent
              writing the notes; rather it depends on the individual
              practitioner and session.
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              If a parent is late for a session the time may be deducted from
              the session as the practitioner may have a schedule to stick to.{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Neither a parent nor a practitioner may change the IFSP frequency
              and/or duration of sessions, even if it is better for the family’s
              schedule (e.g. deciding to do two hours of therapy at one time
              instead of doing one hour twice a week). If an IFSP is not meeting
              the family’s needs, family should contact TA or the service
              coordinator to change the paperwork.
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              A practitioner is not allowed to service after the end date of the
              child’s IFSP service page. A updated IFSP needs to be written at a
              review meeting for services to continue.{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Services may not be provided on the child’s third birthday and
              thereafter.
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Services may not be provided while a family’s account has been
              suspended until the account has been reinstated and new services
              pages are written and signed by the parent.{" "}
            </span>
          </label>
        </p>

        <h3 style={styles.h3Tag}>11. Documentation: </h3>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Service Encounter Verification Form (SEVF). After every session
              the practitioner will present to you (or the child care provider)
              a SEVF, which is an attendance log, to sign. This may be either
              paper or electronic, from the TA website. The practitioner should
              have filled in the date, location, start time and end time prior
              to asking for the signature. The total hours will be computed and
              added at a later time.{" "}
            </span>
          </label>
        </p>

        <div className="html2pdf__page-break"></div>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Signing the form is a testimony and agreement that the
              practitioner did in fact provide the required service at the dates
              and times specified.
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              If a mistake was made on the paper form, the practitioner will ask
              you to initial any cross-outs and corrections.{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Practitioner may bill only for as much time as is spent in the
              session. If a session is cut short due to lateness or other
              reason, the time billed for should reflect only the time spent in
              the session (including note-taking time and discussion time).
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Practitioner will also write up a clinical note on the online
              portal. The clinical note should contain a summary of the
              activities done and recommendations for strategies and activities
              for you to use at home with your child. This note will be
              completed during the IFSP session time.{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              If a practitioner does not present a SEVF to sign, or if you have
              any other questions regarding how to document the sessions
              correctly, please call the TA office.
            </span>
          </label>
        </p>

        <p>
          We look forward to working with you, and hope you and your child has
          an educational and productive relationship with our practitioners!
        </p>
      </div>
    </div>
  );
}

export default EnglishDocx;
