import React, { useEffect, useState } from "react";
import Header from "./Header";
import Cookies from "universal-cookie";
import axios from "axios";
import { APIEndpoints } from "./APIEndpoints";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  setLocalStorageWithExpiration,
  getLocalStorageWithExpiration,
} from "./localStorageUtil";

function NonBillable() {
  const cookie = new Cookies();
  const token = cookie.get("logindata");

  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [checking, setChecking] = useState(false);
  const [clickedSessionId, setClickedSessionId] =
    useState(null);
  const [selectedChild, setSelectedChild] = useState("");

  const fetchData = async () => {
    if (getLocalStorageWithExpiration("task-data")) {
      setIsLoading(true);
      const cookieData = getLocalStorageWithExpiration("task-data");
      setData(cookieData);
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const cookie = new Cookies();
        const token = cookie.get("logindata");
        const { access_token, sf_id } = token;
        const response = await axios.get(`${APIEndpoints.ALL_TASK}${sf_id}`);
        setData(response.data);
        // Set Data in Cookie for 30 minutes
        setLocalStorageWithExpiration("task-data", response.data, 30);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSession = async (sessionId, date) => {
    setClickedSessionId(sessionId);

    const url = APIEndpoints.CONTACT_FOR_SIGNATURE + `${sessionId}/`;
    const method = "POST";
    const requestBody = JSON.stringify({
      service_id: sessionId,
      year_month: date,
    });

    try {
      setChecking(true);
      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: requestBody,
      });

      if (response.status == 200) {
        const responseData = await response.json();
        setChecking(false);
        setClickedSessionId(null);
        navigate("/signature-link", {
          state: { data: responseData, sessionId: sessionId },
        });
      } else {
        // Handle error response here
        console.error("No Child Contacts - Reach Out To Administration");
        setChecking(false);
        setClickedSessionId(null);
        toast.error("No Child Contacts - Reach Out To Administration", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      // Handle network error here
      setChecking(false);
      console.error("Network error:", error);
      toast.error("Error Raised", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const uniqueChildNames = data.not_billable
    ? [
        ...new Set(
          Object.values(data.not_billable).map(
            (item) => item.child_service_name.split(" - ")[0]
          )
        ),
      ]
    : [];

  const handleSelectChange = (event) => {
    setSelectedChild(event.target.value);
  };

  const filteredData = selectedChild
    ? Object.values(data.not_billable).filter((item) =>
        item.child_service_name.startsWith(selectedChild)
      )
    : [];

  return (
    <>
      <Header />
      <ToastContainer style={{ top: "7rem", position: "fixed" }} />
      {isLoading ? (
        <div className="">
          <div className="card-body text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container custom-container my-5">
          <div className="my-3">
            <p>
              <strong>
                <i className="fa fa-filter" aria-hidden="true"></i> Filter Child
              </strong>
            </p>
            <select
              className="form-select"
              aria-label="Select Child Name"
              onChange={handleSelectChange}
              value={selectedChild}
            >
              <option value="">All children</option>
              {uniqueChildNames.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          {filteredData.length > 0 ? (
            filteredData.map((value, index) => (
              <div
                key={index}
                className="box p-3 mb-3 rounded"
                onClick={() => handleSession(value?.session_id, value?.date)}
              >
                {clickedSessionId === value?.session_id ? (
                  <div className="text-center">
                    <div
                      className="spinner-border m-auto text-center"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div>
                      <p className="ms-2">
                        <strong>{value?.name.split("-")[0]}</strong>
                        <br />
                        {value?.date} {value?.start_time} - {value?.end_time}
                      </p>
                    </div>
                  </>
                )}
              </div>
            ))
          ) : (
            <div>
              {data.not_billable &&
              Object.values(data.not_billable).length > 0 ? (
                Object.values(data.not_billable).map((value, index) => (
                  <div
                    key={index}
                    className="box d-flex flex-wrap justify-content-between p-3 mb-3 rounded"
                    onClick={() =>
                      handleSession(value?.session_id, value?.date)
                    }
                  >
                    {clickedSessionId === value?.session_id ? (
                      <div className="spinner-border m-auto" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <>
                        <div>
                          <p className="ms-2">
                            <strong>{value?.name.split("-")[0]}</strong>
                            <br />
                            {value?.date} {value?.start_time} -{" "}
                            {value?.end_time}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                ))
              ) : (
                <p className="text-center">There is nothing to process</p>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default NonBillable;

