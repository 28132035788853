import React, { useState, useEffect } from "react";
import Header from "./Header";
import { useLocation, useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { APIEndpoints } from "./APIEndpoints";
import { getTimeElapsedFromLocalStorage } from "./localStorageUtil";
import LastUpdatedTime from "./LastUpdatedTime";
import axios from "axios";

function ChildDetail() {
  const location = useLocation();

  const [isloading, setIsLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const [name, setname] = useState(location?.state?.name);
  const [serviceId, setServiceId] = useState(location?.state?.id);
  const [childID, setChildId] = useState(location?.state?.childID);
  const [therapistServiceId, setTherapistServiceId] = useState(
    location?.state?.therapistServiceId
  );

  const cookie = new Cookies();
  const token = cookie.get("logindata");

  const [descriptionOptions, setDescriptionOptions] = useState([]);

  const [lastUpdatedTime, setLastUpdatedTime] = useState(0);

  useEffect(() => {
    const value = getTimeElapsedFromLocalStorage("serviceDetails");
    setLastUpdatedTime(value);
  }, []);

  const navigate = useNavigate();
  const fetchDescriptionOptions = async () => {
    if (!childID || !therapistServiceId) {
      navigate('/');
      return
    }
    try {
      setIsLoading(true);
      const { access_token } = token;

      const response = await fetch(APIEndpoints.SERVICE_DETAIL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify({
          therapist_service_id: therapistServiceId,
          child_id: childID,
        }),
      });

      const data = await response.json();
      setDescriptionOptions(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDescriptionOptions();
  }, []);

  function downloadBase64File(base64Data, fileName) {
    try {
      const byteCharacters = atob(base64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
    } catch (error) {
      console.error("Error creating object URL:", error);
    }
  }

  return (
    <>
      <Header />
      {isloading || descriptionOptions.length > 0 ? (
        <div className="detail-page container-fluid">
          <div className="container my-4">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="container custom-container my-5">
            <div className="p-3">
              <h5>{name}</h5>
              <p className="mb-0">
                Service Type: {descriptionOptions?.service_name}
              </p>
              <p className="mb-0">
                Service start Date:{" "}
                {descriptionOptions?.service_start_date ? descriptionOptions?.service_start_date.replace(
                  /-/g,
                  "/"
                ) : ""}
              </p>
              <p className="mb-0">
                Interval: {descriptionOptions.service_time}
              </p>
              <p className="mb-0">
                Consent Document Signed:{" "}
                {descriptionOptions.consent_doc_signed
                  ? "Signed"
                  : "Not signed"}
              </p>
              {descriptionOptions.consent_doc_signed ? (
                <p className="mb-0">
                  Consent Document:{" "}
                  <span
                    className="text-primary cursor-pointer"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      downloadBase64File(
                        descriptionOptions.consent_doc,
                        "Consent Form"
                      );
                    }}
                  >
                    Download
                  </span>
                </p>
              ) : (
                ""
              )}
            </div>

            {descriptionOptions.assigned_date !== null &&
            descriptionOptions.assigned_date <=
              new Date().toISOString().split("T")[0] ? (
              <>
                <div
                  className="box d-flex justify-content-between p-3 mb-3 my-2 rounded"
                  onClick={(e) => {
                    navigate("/eims-detail", {
                      state: {
                        name: name,
                        service_id: descriptionOptions.child_service_id,
                        childID: childID,
                        therapistServiceId: therapistServiceId,
                        serviceLength: descriptionOptions.service_length,
                        assignedDate: descriptionOptions.assigned_date,
                        serviceSpecialty: descriptionOptions.service_specialty,
                        policySigned: descriptionOptions.policy_doc_signed,
                        policy_datetime: descriptionOptions.policy_datetime,
                      },
                    });
                  }}
                >
                  <div>
                    <h5 className="ms-2">Log a session</h5>
                    <p className="ms-2">
                      Click to here to log a current session and submit a
                      previous session
                    </p>
                  </div>
                  <div className="my-auto">
                    <i className="fa-solid fa-arrow-right"></i>
                  </div>
                </div>
              </>
            ) : (
              " "
            )}

            <div
              className="box d-flex justify-content-between p-3 mb-3 my-2 rounded"
              onClick={(e) => {
                navigate("/parent-contacts", {
                  state: {
                    service_id: descriptionOptions.child_service_id,
                    childID: childID,
                    therapistServiceId: therapistServiceId,
                  },
                });
              }}
            >
              <div>
                <h5 className="ms-2">Parent Contact Form</h5>
                <p className="ms-2">
                  Click to here to add a parent contact attempt
                </p>
              </div>
              <div className="my-auto">
                <i className="fa-solid fa-arrow-right"></i>
              </div>
            </div>

            <div
              className="box d-flex justify-content-between p-3 mb-3 my-2 rounded"
              onClick={(e) => {
                navigate("/child-consent-contact", {
                  state: {
                    childId: childID,
                    childServiceId: descriptionOptions.child_service_id,
                    therapistServiceId: descriptionOptions.therapist_service_id,
                  },
                });
              }}
            >
              <div>
                <h5 className="ms-2">Child Consent Form</h5>
                <p className="ms-2">Click to here send child consent form</p>
              </div>
              <div className="my-auto">
                <i className="fa-solid fa-arrow-right"></i>
              </div>
            </div>

            {/*<div
              className="box d-flex justify-content-between p-3 mb-3 rounded"
              onClick={(e) => {
                navigate("/child-form", {
                  state: { name: name, description: descriptionOptions },
                });
              }}
            >
              <div>
                <h5 className="ms-2">Form 25</h5>
                <p className="ms-2">
                  Please complete tasks to ensure timely payments and full
                  compliance
                </p>
              </div>
              <div className="my-auto">
                <i className="fa-solid fa-arrow-right"></i>
              </div>
            </div>*/}

            <div
              className="box d-flex justify-content-between p-3 mb-3 rounded"
              onClick={(e) => {
                navigate("/child-contacts", {
                  state: {
                    childServiceId: descriptionOptions.child_service_id,
                    therapistServiceId: descriptionOptions.therapist_service_id,
                  },
                });
              }}
            >
              <div>
                <h5 className="ms-2">Child Contact</h5>
                <p className="ms-2">Contacts related to child</p>
              </div>
              <div className="my-auto">
                <i className="fa-solid fa-arrow-right"></i>
              </div>
            </div>

            {/* <div className="box d-flex justify-content-between p-3 mb-3 rounded">
              <div>
                <h5 className="ms-2">Parent Contact Form</h5>
                <p className="ms-2">
                  Document communication with family to schedule a start date
                </p>
              </div>
              <div className="my-auto">
                <i className="fa-solid fa-arrow-right"></i>
              </div>
            </div> */}

            <div
              className="box d-flex justify-content-between p-3 mb-3 rounded"
              onClick={(e) => {
                navigate("/session-history", {
                  state: {
                    descriptionOptions: descriptionOptions,
                  },
                });
              }}
            >
              <div>
                <h5 className="ms-2">Session History</h5>
                <p className="ms-2">
                  Please complete tasks to ensure timely payments and full
                  compliance
                </p>
              </div>
              <div className="my-auto">
                <i className="fa-solid fa-arrow-right"></i>
              </div>
            </div>

            {/* <div className="box d-flex justify-content-between p-3 mb-3 rounded">
              <div>
                <h5 className="ms-2">Tasks Due</h5>
                <p className="ms-2">Tasks due related to Adam Gold</p>
              </div>
              <div className="my-auto">
                <i className="fa-solid fa-arrow-right"></i>
              </div>
            </div> */}
          </div>
        </>
      )}
      {lastUpdatedTime ? (
        <LastUpdatedTime time={lastUpdatedTime} />
      ) : (
        <LastUpdatedTime time={"0 min ago"} />
      )}
    </>
  );
}

export default ChildDetail;
