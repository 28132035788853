import React from "react";
import FormFile from "../Assets/pdf/NJEIS Form 25 Word.docx";
import Header from "./Header";

function Form25Download() {
  return (
    <>
      <Header />
      <div className="container custom-container my-5">
        <div className="box text-center p-3 mb-3 my-2 rounded d-flex flex-column justify-content-between">
          <p className="ms-2">
            Please complete a Form 25 for this child and email a copy to
            <a
              className="ms-2"
              style={{
                color: "blue",
                textDecoration: "none",
              }}
              href="mailto:administrator@therapyassociates.com"
            >
              administrator@therapyassociates.com
            </a>
          </p>
          <p>
            Please click here to
            <a
              className="mt-2 mx-2 text-decoration-none text-primary"
              href={FormFile}
              download
            >
              download
            </a>
            the Form 25
          </p>
        </div>
      </div>
    </>
  );
}

export default Form25Download;
