import React, { useState, useEffect } from "react";
import Header from "./Header";
import { useLocation, useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { APIEndpoints } from "./APIEndpoints";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ChildContacts() {
  const location = useLocation();
  const navigate = useNavigate();

  const [isloading, setIsLoading] = useState(false);

  const [childServiceId, setChildServiceId] = useState(
    location?.state?.childServiceId
  );
  const [therapistServiceId, setTherapistServiceId] = useState(
    location?.state?.therapistServiceId
  );

  const [parentContacts, setParentContacts] = useState([]);
  const [relatedTherapists, setRelatedTherapists] = useState([]);

  const cookie = new Cookies();
  const token = cookie.get("logindata");

  const fetchContactData = async () => {
    try {

      if (!therapistServiceId || !childServiceId) {
        navigate("/");
        return;
      }

      setIsLoading(true);
      const { access_token } = token;

      const response = await axios.post(
        APIEndpoints.CHILD_ALL_CONTACTS,
        {
          therapist_service_id: therapistServiceId,
          child_service_id: childServiceId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      const data = response.data;

      setParentContacts(data.data.child_contacts);
      setRelatedTherapists(data.data.child_related_therapists);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchContactData();
  }, []);

  const [contactType, setContactType] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);

  const openModal = (contact, type) => {
    setContactType(type);
    setSelectedContact(contact);
    handleShow();
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Header />
      {isloading ? (
        <div className="detail-page container-fluid">
          <div className="container my-4">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="container custom-container my-5">
            <div className="d-flex flex-row justify-content-center">
              {parentContacts.length > 0 || relatedTherapists.length > 0 ? (
                <>
                  {parentContacts.length > 0 && (
                    <div className="contact-section">
                      <h4 className="mb-4 text-center">Parent Contacts</h4>
                      {parentContacts.map((contact) => (
                        <div
                          key={contact.Id}
                          className="contactBoxStyle"
                          onClick={() => openModal(contact, "contact")}
                        >
                          <p>{contact.relation} - {contact.name}</p>
                        </div>
                      ))}
                    </div>
                  )}

                  {relatedTherapists.length > 0 && (
                    <div className="ms-4 contact-section">
                      <h4 className="mb-4 text-center">Therapist Contacts</h4>
                      {relatedTherapists.map((contact) => (
                        <div
                          key={contact.Id}
                          className="contactBoxStyle"
                          onClick={() => openModal(contact, "therapist")}
                        >
                          <p>{contact.name}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <>No data to show</>
              )}
            </div>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>{contactType === "contact" ? "Contact Details" : "Therapist Details"}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {selectedContact ? (
                  <>
                    { contactType === "contact" ? (
                      <>
                        <p>
                          <strong>Relation</strong>: {selectedContact.relation}
                        </p>
                      </>
                    ) : (
                      <>
                        <p>
                          <strong>Title</strong>:{" "}
                          {selectedContact.service_name}
                        </p>
                      </>
                    )}
                    <p>
                      <strong>Name</strong>: {selectedContact.name}
                    </p>
                    <p>
                      <strong>Contact</strong>: {selectedContact.phone}
                    </p>
                    <p>
                      <strong>Email</strong>: {selectedContact.email}
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                {/* <Button variant="primary" onClick={handleClose}>
                  Save Changes
                </Button> */}
              </Modal.Footer>
            </Modal>
          </div>
        </>
      )}
    </>
  );
}

export default ChildContacts;
