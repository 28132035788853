import React, { useEffect, useState } from "react";
import Header from "./Header";
import { APIEndpoints } from "./APIEndpoints";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ChildContactSelection() {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [childID, setChildId] = useState(location?.state?.childID);
    const navigate = useNavigate()

    const fetchDescriptionOptions = async () => {
        try {


            if (!childID) {
              navigate("/");
              return;
            }

            setLoading(true);

            const response = await axios.get(
                `${APIEndpoints.PARENT_CHILD_CONTACTS}${childID}`
            );
            setData(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchDescriptionOptions();
    }, []);

    const handelSubmit = async (event) => {
        setIsLoading(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        const contact_id =  formData.get("child_contact")
        navigate(`/policy-procedure/${childID}/${contact_id}`);
    }

    return (
        <>
            <ToastContainer />
            <Header />
            {loading ? (
                <div className="detail-page container-fluid">
                    <div className="container my-4">
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container custom-container my-5">
                    <div className="container-fluid my-5 h-100">
                        <div
                            className="container card my-4"
                            style={{ background: "transparent" }}
                        >
                            <div className="row p-2 text-center">
                                <h5>Select a child contact to sign the policy procedure document</h5>
                            </div>
                            <hr className="m-0" />
                            <div className="row my-4">
                                <form onSubmit={handelSubmit}>
                                    <div className="mb-2 row">
                                        <label className="col-form-label">Child Contact</label>
                                    </div>
                                    <div className="mb-2">
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            name="child_contact"
                                            required
                                        >
                                            <option value="">Select a child contact</option>
                                            {data &&
                                                data.map((child) => (
                                                    <option key={child.id} value={child.id}>
                                                        {child.name}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="text-center">
                                        {isLoading ? (
                                            <Button variant="dark" disabled>
                                                Submitting...
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            </Button>
                                        ) : (
                                            <input
                                                type="submit"
                                                className="btn btn-dark text-white"
                                                value="Submit"
                                            />
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ChildContactSelection;
