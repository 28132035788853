import React from 'react'
import { Dropdown } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import { clearAllLocalStorage } from './localStorageUtil';

function Header() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  const handleClearCookie = () => {
    removeCookie('logindata');
    // Remove all data from local storage
    clearAllLocalStorage();
    window.location.href = '/login';
  };
  return (
    <>
      <div className="fixed-header">
        <div className="container">
          <div className="d-flex justify-content-between">
            <div className="left-arrrow" onClick={() => navigate(-1)}>
              <i className="fa-solid fa-arrow-left p-1"></i>
            </div>
            <div className="d-flex justify-content-center text-center ">
              <div className="col-5 mt-2" style={{ marginRight: "60px" }}>
                <img
                  src="/Therapy Associates_Logo.png"
                  alt="logo"
                  style={{ maxWidth: "170px", cursor: "pointer" }}
                  onClick={() => navigate("/")}
                />
              </div>
            </div>
            <div className="option">
              <i
                className="fa-solid fa-arrow-right-from-bracket p-2"
                id="logout-btn"
                onClick={handleClearCookie}
              ></i>
              <Dropdown id="header-option">
                <Dropdown.Toggle
                  id="dropdown-basic"
                  style={{
                    border: "none",
                    background: "transparent",
                  }}
                >
                  <i className="fa-solid fa-ellipsis-vertical text-dark"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleClearCookie}>
                    <i className="fa-solid fa-arrow-right-from-bracket"></i>{" "}
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </>
  );
}

export default Header
