import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { APIEndpoints } from "./APIEndpoints";
import { Cookies } from "react-cookie";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";

function Availability() {
  const navigate = useNavigate();

  const [weeklyHours, setWeeklyHours] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    console.log(value);
    if (value === "" || (value >= 0 && value <= 50)) {
      setWeeklyHours(value);
    } else {
      toast.warning("Time be greater than 0 and less than 50.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setWeeklyHours("");
    }
  };

  const handelSubmit = async () => {
    try {
      setIsLoading(true);
      const cookie = new Cookies();
      const token = cookie.get("logindata");
      const { sf_id } = token;
      const response = await axios.post(
        `${APIEndpoints.THERAPIST_AVAILABLE_TIME}${sf_id}/${weeklyHours}/`
      );
      setIsLoading(false);
      toast.success("Successfullly Updated", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setWeeklyHours("");
      fetchData();
    } catch (error) {
      console.error("Something went wrong:", error);
      setIsLoading(false);
      setWeeklyHours("");
    }
  };

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = React.useState(false);

  const handleClose = () => {
    setShow(false);
    handelSubmit();
  };
  const handleShow = () => setShow(true);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const cookie = new Cookies();
      const token = cookie.get("logindata");
      const { sf_id } = token;
      const response = await axios.get(
        `${APIEndpoints.THERAPIST_AVAILABLE_TIME}${sf_id}/`
      );
      setData(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header />
      <ToastContainer style={{ top: "7rem", position: "fixed" }} />
      <div className="container custom-container my-5">
        <div className="d-flex justify-content-between mb-2">
          <p>Weekly Hours Available : {data}</p>

          <button
            type="button"
            className="btn btn-dark btn-sm"
            onClick={handleShow}
            // onClick={()=>{alert("hii")}}
          >
            Update Available Weekly Hours
          </button>
        </div>
        <Link to="/therapist/availability" className="linkbox">
          <div className="box d-flex justify-content-between p-3 mb-3 my-2 rounded">
            <div>
              <p className="ms-2">
                Select the time you are available to accept new cases
              </p>
            </div>
            <div className="my-auto">
              <i className="fa-solid fa-arrow-right"></i>
            </div>
          </div>
        </Link>
        <Link to="/therapist/regions" className="linkbox">
          <div className="box d-flex justify-content-between p-3 mb-3 my-2 rounded">
            <div>
              <p className="ms-2">Select the areas you serve</p>
            </div>
            <div className="my-auto">
              <i className="fa-solid fa-arrow-right"></i>
            </div>
          </div>
        </Link>

        {/* <div className='p-3'>
          <p className='fw-semibold'>Available cases that match your availble and service ares </p>
        </div>

        <div className='box d-flex flex-column p-3 mb-3 my-2 rounded'>
          <div>
            <h6 className='ms-2'>Physical Therapy in Passiac Area</h6>
            <p className='ms-2 fw-light'>3x per week. Available sunday-thurs 11AM - 4PM </p>
          </div>
          <div className='my-auto text-end'>
            <Link to="/availability-areas">
              <button type="button" className="btn availability-btn">I am interested in taking this case</button>
            </Link>
          </div>
        </div>

        <div className='box d-flex flex-column p-3 mb-3 my-2 rounded'>
          <div>
            <h6 className='ms-2'>Physical Therapy in Passiac Area</h6>
            <p className='ms-2 fw-light'>3x per week. Available sunday-thurs 11AM - 4PM </p>
          </div>
          <div className='my-auto text-end'>
            <Link to="/availability-areas">
              <button type="button" className="btn availability-btn">I am interested in taking this case</button>
            </Link>
          </div>
        </div>

        <div className='box d-flex flex-column p-3 mb-3 my-2 rounded'>
          <div>
            <h6 className='ms-2'>Physical Therapy in Passiac Area</h6>
            <p className='ms-2 fw-light'>3x per week. Available sunday-thurs 11AM - 4PM </p>
          </div>
          <div className='my-auto text-end'>
            <Link to="/availability-areas">
              <button type="button" className="btn availability-btn">I am interested in taking this case</button>
            </Link>
          </div>
        </div> */}
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label for="exampleInputEmail1">Weekly Hour available</label>
            <input
              type="number"
              className="form-control my-1"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter Available Weekly Hours"
              min={0}
              max={50}
              value={weeklyHours}
              onChange={handleChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="dark" onClick={handleClose}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Availability;
