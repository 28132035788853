import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import { APIEndpoints } from "./APIEndpoints";
import axios from "axios";
import Cookies from "universal-cookie";
import {
  setLocalStorageWithExpiration,
  getLocalStorageWithExpiration,
} from "./localStorageUtil";

function SessionIssues() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [formTask, setFormTask] = useState([]);

  const cookie = new Cookies();
  const billableSubmittedTask = cookie.get("clickedTasks")
    ? cookie.get("clickedTasks").length
    : 0;

  const fetchData = async () => {
    if (getLocalStorageWithExpiration("task-data")) {
      setIsLoading(true);
      const cookieData = getLocalStorageWithExpiration("task-data");
      setData(cookieData);
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const cookie = new Cookies();
        const token = cookie.get("logindata");
        const { access_token, sf_id } = token;
        const response = await axios.get(`${APIEndpoints.ALL_TASK}${sf_id}`);
        setData(response.data);
        console.log(response.data);
        // Set Data in Cookie for 30 minutes
        setLocalStorageWithExpiration("task-data", response.data, 30);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header />

      {isLoading ? (
        <div className="">
          <div className="card-body text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container custom-container my-5">
          {data.missing_sf_sessions && data.missing_sf_sessions.length > 0 ? (
            <>
              <Link to="/session-missing" className="linkbox">
                <div className="box position-relative d-flex justify-content-between p-3 mb-3 rounded">
                  {data.missing_sf_sessions &&
                  data.missing_sf_sessions.length > 0 ? (
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                      {data.missing_sf_sessions.length}
                    </span>
                  ) : (
                    ""
                  )}
                  <div>
                    <h5 className="ms-2">
                      {data.missing_sf_sessions &&
                      data.missing_sf_sessions.length > 0 ? (
                        <span>
                          {data.missing_sf_sessions.length} Sessions Not In TA
                          System
                        </span>
                      ) : (
                        "No Sessions Not In TA System"
                      )}
                    </h5>
                    <p className="ms-2">
                      Sessions you've logged in EIMS that have not been logged
                      in the Therapy Associates app.
                    </p>
                  </div>
                  <div className="my-auto">
                    <i className="fa-solid fa-arrow-right"></i>
                  </div>
                </div>
              </Link>
            </>
          ) : (
            ""
          )}

          {data.missing_eims && Object.keys(data.missing_eims).length > 0 ? (
            <>
              <Link to="/eims-task" className="linkbox">
                <div className="box position-relative d-flex justify-content-between p-3 mb-3 rounded">
                  {data.missing_eims &&
                  Object.keys(data.missing_eims).length > 0 ? (
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                      {Object.keys(data.missing_eims).length}
                    </span>
                  ) : (
                    ""
                  )}
                  <div>
                    <h5 className="ms-2">
                      {data.missing_eims &&
                      Object.keys(data.missing_eims).length > 0 ? (
                        <span>
                          {Object.keys(data.missing_eims).length} Unreconciled
                          Session(s)
                        </span>
                      ) : (
                        "No Unreconciled Sessions"
                      )}
                    </h5>
                    {/* <p className="ms-2">
                      You are required to complete the missing EIMS task.{" "}
                    </p> */}
                    <p className="ms-2">
                      Please ensure that these sessions are accurately logged in
                      EIMS. If you have already done so, please allow up to
                      three days for us to reconcile them with your TA session
                      logs.
                    </p>
                  </div>
                  <div className="my-auto">
                    <i className="fa-solid fa-arrow-right"></i>
                  </div>
                </div>
              </Link>
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
}

export default SessionIssues;
