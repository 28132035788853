import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { APIEndpoints } from "./APIEndpoints";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Button from "react-bootstrap/Button";
import ArabicDocs from "./PolicyProdurePages/ArabicDocx";
import EnglishDocs from "./PolicyProdurePages/EnglishDocx";
import SpanishDocs from "./PolicyProdurePages/SpanishDocs";
import SignatureCanvas from "react-signature-canvas";

function PolicyProcedureSign() {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [apiCall, setApiCall] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState(
    location?.state?.selectedLanguage
  );
  const [childId, setChildId] = useState(location?.state?.childId);
  const [childContactId, setChildContactId] = useState(
    location?.state?.childContactId
  );

  useEffect(() => {
    if (!selectedLanguage) {
      navigate("/");
    }
  }, [selectedLanguage, navigate]);

  const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      // Change the threshold value as needed
      if (scrollPosition > 200) {
        setShowDiv(true);
      } else {
        setShowDiv(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isSubmitted, setIsSubmitted] = useState(false);

  // PDF GENERATION

  const componentRef = useRef(null);

  // Submit Document
  const handelSubmit = async () => {
    try {
      if (!childId || !childContactId) {
        navigate("/");
        return;
      }

      setIsSubmitted(true);

      if (!file) {
        toast.error("Please upload signature first.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        setIsSubmitted(false);
        return;
      }
      setLoading(true);
      setIsSubmitted(false);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("language", selectedLanguage);
      // formData.append("file", pdfFile);

      setApiCall(true);

      const apiUrl = `${APIEndpoints.SAVE_POLICY_PROCEDURE_DOCUMENT}${childId}/${childContactId}/`;

      // Make the POST request to the API using Axios
      const response = await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Handle the response from the API
      const data = response.data;
      setApiCall(false);
      toast.success("Successfully Submitted", {
        position: toast.POSITION.TOP_RIGHT,
      });
      navigate("/thank-you");
      setLoading(false);
    } catch (error) {
      toast.error("Error in Sign Upload", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setApiCall(false);
      setLoading(false);
    }
  };

  // LOGIC FOR SIGNATURE PAD

  const [file, setFile] = useState();

  const signatureCanvasRef = useRef(null);

  const clearSignature = () => {
    signatureCanvasRef.current.clear();
    setFile(null);
  };

  const saveSignature = () => {
    const signatureImage = signatureCanvasRef.current.toDataURL();
    setFile(signatureImage);
  };

  const divRef = useRef();

  return (
    <>
      <ToastContainer />

      {apiCall ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="container custom-container" ref={componentRef}>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <div className="my-4">
                {selectedLanguage === "English" && <EnglishDocs />}
                {selectedLanguage === "Arabic" && <ArabicDocs />}
                {selectedLanguage === "Spanish" && <SpanishDocs />}
              </div>

              {!isSubmitted && (
                <div
                  className="w-100"
                  ref={divRef}
                  style={{ background: "rgb(248, 248, 248)", padding: "10px" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <a
                      className="position-absolute signature-erase-policy signature-erase"
                      onClick={clearSignature}
                    >
                      <i className="fa-solid fa-eraser"></i>
                    </a>
                    <SignatureCanvas
                      ref={signatureCanvasRef}
                      penColor="black"
                      canvasProps={{
                        width: 500,
                        height: 200,
                        className: "signatureCanvas",
                      }}
                      onEnd={saveSignature}
                    />
                  </div>
                </div>
              )}

              {file && (
                <div className="w-100">
                  <div
                    className="text-center position-relative"
                    style={{
                      background: "rgb(248, 248, 248)",
                    }}
                  >
                    <div className="p-2">
                      <img
                        src={file}
                        style={{
                          width: "30%",
                          border: "1px solid black",
                        }}
                        alt="signature Image"
                      />
                    </div>
                  </div>
                </div>
              )}

              {!isSubmitted && (
                <div className="text-center my-2">
                  <Button
                    className="btn btn-dark text-white my-4 px-4"
                    onClick={() => {
                      handelSubmit();
                    }}
                    disabled={loading}
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PolicyProcedureSign;
