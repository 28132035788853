export const json = {
    "elements": [
      {
        "type": "signaturepad",
        "name": "signature",
        "title": "Please sign here",
        "isRequired": true,
        "signatureWidth": 600
      }
    ],
    "showQuestionNumbers": false
  };