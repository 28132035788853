
function setLocalStorageWithExpiration(key, value, expirationInMinutes) {
  const now = new Date();
  const item = {
    value: value,
    expiration: now.getTime() + expirationInMinutes * 60 * 1000,
    timeStored: now.getTime() // Add the timeStored property
  };
  localStorage.setItem(key, JSON.stringify(item));
}


function getLocalStorageWithExpiration(key) {
  const storedItem = localStorage.getItem(key);

  if (storedItem) {
    const item = JSON.parse(storedItem);

    if (new Date().getTime() < item.expiration) {
      return item.value;
    } else {
      localStorage.removeItem(key);
    }
  }

  return null;
}


function removeLocalStorageItem(key, delayInMinutes = 5) {
  // Remove the item after a specified delay (default is 5 minutes)
  if (delayInMinutes === 0) {
    localStorage.removeItem(key);
  } else {
    setTimeout(() => {
      localStorage.removeItem(key);
    }, delayInMinutes * 60 * 1000);
  }
}

function clearAllLocalStorage() {
  localStorage.clear();
}

function getTimeElapsedFromLocalStorage(key) {
  const storedItem = localStorage.getItem(key);

  if (storedItem) {
    const item = JSON.parse(storedItem);
    const currentTime = new Date().getTime();

    if (currentTime < item.expiration) {
      // Calculate the time elapsed in milliseconds
      const timeElapsed = currentTime - item.timeStored;

      // Calculate the time elapsed in minutes
      const minutesElapsed = Math.floor(timeElapsed / (1000 * 60));

      return `${minutesElapsed}m`;
    } else {
      // Handle the case when the data is expired
      // For example, you can remove the item from local storage
      localStorage.removeItem(key);
      return null;
    }
  }

  return null; // Handle the case when the data is not available
}



export { setLocalStorageWithExpiration, getLocalStorageWithExpiration, removeLocalStorageItem, clearAllLocalStorage, getTimeElapsedFromLocalStorage };

