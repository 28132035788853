import React from "react";

function SpanishDocs() {
  const styles = {
    h3Tag: {
      fontSize: "21px",
    },
    heading: {
      textAlign: "center",
      margin: "5px 0",
      fontSize: "15px",
    },
    container: {
      fontFamily: "Arial, sans-serif",
      margin: "auto",
    },
    guidelines: {
      marginTop: "20px",
      fontWeight: "500",
      fontSize: "20px",
    },
    ul: {
      listStyleType: "none",
      paddingLeft: 0,
    },
    checkboxContainer: {
      input: {
        transform: "scale(1.5)",
      },
    },
    checkboxLabel: {
      alignItems: "center",
      marginLeft: "10px",
    },
  };

  return (
    <div className={styles.container}>
      <p style={styles.heading}>Guia para los padres</p>
      <p style={styles.heading}>
        Por sus servicios de Intervencion Temprana con
      </p>
      <p style={styles.heading}>Therapy Associates LLC</p>

      <div className={styles.guidelines}>
        <p>
          1. ¡Bienvenido a Therapy Associates! Nos complace tratar de ayudarle
          con cualquier necesidad o inquietud, sea con Intervencion Temprana en
          general o con su proveedor. El número de teléfono de TA es
          973-471-3046. Nuestra página web es www.therapyassociates.com y
          nuestro correo electrónico es casemanagement@therapyassociates.com.{" "}
        </p>

        <p>
          2. Modelo de Capacitación Familiar y Frecuencia de Servicio: El
          objetivo y la filosofía primordiales del Sistema de intervención
          temprana de NJ es potenciar y capacitar a USTED (los padres) para
          facilitar el crecimiento y desarrollo de sus hijos. Los profesionales
          se envían a las familias principalmente para educar a usted y a sus
          otros cuidadores sobre cómo promover este desarrollo. Por lo tanto,
          los servicios se brindan en el entorno natural de su hijo, utilizando
          los juguetes y el entorno del niño.
        </p>

        <p>
          3. Seguridad e Higiene: tenga en cuenta que Therapy Associates toma
          muy en serio la seguridad y la salud de las familias y los
          profesionales. Tenga en cuenta los siguientes procedimientos que hemos
          instituido para promover la seguridad e higiene para todos:{" "}
        </p>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Asegúrese de que las instalaciones de la casa o del cuidado de los
              niños sean seguras y limpias. El practicante no está obligado a
              proporcionar servicios en un entorno que considere inseguro o
              insalubre.
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              El practicante no está permitido tratar a un niño a menos que haya
              otro adulto presente durante toda la sesión que esté despierto y
              disponible. El padre / cuidador no puede abandonar el hogar (o el
              lugar de la terapia) por ningún motivo durante una sesión.
            </span>
          </label>
        </p>
        <label style={styles.checkboxContainer}>
          <span style={styles.checkboxLabel}>
            Si un profesional que atiende a una familia está en el hogar solo
            con el niño y un adulto del sexo opuesto, se le indica al
            practicante que deje la puerta abierta un poco. Esto es para
            proteger a todos de cualquier suceso impropio. Por favor permita que
            el practicante siga esta instrucción.
          </span>
        </label>

        <div className="html2pdf__page-break"></div>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Un practicante no puede tratar a un niño si está enfermo, ni se le
              puede dar servicio a un niño si está enfermo. Además, un
              profesional no puede tratar a un niño si el hermano u otra persona
              en el hogar tiene una enfermedad contagiosa.
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              El padre es responsable de informar al practicante cuando un niño
              está enfermo o cuando alguien más en el hogar es contagioso.{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              El padre es responsable de avisar al profesional cuando hay un
              contagio en el niño o en el hogar, como chinches o piojos. El
              terapeuta tiene discreción sobre cuándo tratar en estas
              circunstancias. Es posible que se requiera una carta de
              autorización médica para reanudar los servicios, según la
              situación.{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Cuando es el momento para que el profesional escriba la nota de
              progreso, debe entregar la responsabilidad de vigilar al niño al
              padre o niñera, y se entiende que el padre o la niñera se
              asegurará de la supervisión adecuada en ese momento. El
              practicante no puede mirar al niño y escribir la nota al mismo
              tiempo.{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Si un niño ha tenido una cirugía o una enfermedad grave, se puede
              solicitar una nota de autorización médica antes de la reanudación
              de los servicios.
            </span>
          </label>
        </p>

        <p>
          4. Programación: Todos los profesionals deben establecer un horario
          coherente con usted. Los días y horarios de servicio no deben cambiar
          de una semana a otra. Si no se cumple con un horario consistente, TA
          puede suspender los servicios.
        </p>

        <h3 style={styles.h3Tag}>5. Políza de Asistencia: </h3>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              El niño debe estar presente y disponible para comenzar la terapia
              en el momento en que se programa la sesión. Esto significa que el
              padre puede escuchar la llegada de la terapista y abrir la puerta,
              el niño está despierto, alimentado, no está ocupado con otra
              terapia o actividad, y con un pañal limpio.{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Cualquier demora desde el momento en que el terapista llega a la
              hora programada se deduce del tiempo disponible para atender al
              niño sin tiempo de recuperación adeudado.{" "}
            </span>
          </label>
        </p>

        <div className="html2pdf__page-break"></div>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Si un niño no está disponible en absoluto (es decir, está
              durmiendo, enfermo o no está en casa o en la guardería), y no se
              ha realizado ninguna comunicación para cancelar la sesión, la
              sesión se cuenta como un "no show". El terapista puede esperar
              hasta 15 minutos para que venga la familia, si puede, y luego
              abandonará la casa o la guardería. No se darán recuperaciones por
              "no shows".{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Si un niño / familia no se presenta tres veces, TA puede
              interrumpir los servicios por completo y no se permiten
              recuperaciones .
            </span>
          </label>
        </p>

        <h3 style={styles.h3Tag}>6. Cancelación de una Sesión:</h3>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Si una familia necesita cancelar los servicios, se debe hacer una
              llamada al terapista con 24 horas de anticipación.
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Una cancelación en la mañana de una sesión programada se cuenta
              como un "no show". Como se mencionó anteriormente, no se ofrecen
              recuperaciones para los no shows, y si un niño o una familia no se
              presenta tres veces, TA puede detener los servicios por completo
              sin recuperacion permitido.
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Las cancelaciones múltiples y continuas de una terapia pueden
              provocar el cese de los servicios, ya que un terapista ha
              reservado el intervalo de tiempo para su hijo y no puede programar
              a nadie más a esa hora.
            </span>
          </label>
        </p>

        <p>
          7. Cancelación de Servicios: si ya no desea continuar con las sesiones
          de IE, infórmeselo a su terapista, a Therapy Associates o su
          coordinador de servicios.{" "}
        </p>

        <h3 style={styles.h3Tag}>
          8. La Póliza del estado de Nueva Jersey sobre el suministro de
          recuperación para las sesiones de Intervención Temprana faltantes es
          la siguiente:
        </h3>

        <div className="html2pdf__page-break"></div>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              El practicante tiene el derecho de estar ausente en días festivos
              legales y religiosos sin tener que ofrecer recuperación . Estas
              fechas están escritas en el calendario anual de TA que se
              encuentra en la pagina web. (www.therapyassociates.com), o en un
              día diferente si celebran un festiv religiosa diferente. La
              practicante debe informar a la familia cuando planea observar un
              festivo legal o religioso.{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              El profesional / agencia no está autorizado a ofrecer una sesión
              de recuperación en caso de cancelación debido a una situación
              climática extrema, o cuando el padre cancela una sesión por
              cualquier motivo. Cuando la ubicación del servicio es en una
              guardería o una niñera, el profesional / la agencia no está
              obligado a ofrecer sesiones de recuperacion cuando la guardería o
              la niñera está cerrada o se cancela. En esta situación, si él /
              ella tiene tiempo, el profesional puede ofrecer una sesión
              reprogramada hasta el final de la semana (sábado) pero no más
              tarde.
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              {" "}
              El practicante tiene derecho a cancelar sesiones en alguna
              ocasión. Un practicante debe ofrecer una sesión de recuperación
              para esa sesión. Si las fechas / horarios de recuperación
              ofrecidos no cumplen con ninguna de su disponibilidad, háganoslo
              saber. También puede hablar con su coordinador de servicios para
              solicitar servicios compensatorios.
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              {" "}
              Las sesiones perdidas antes de una reunión de revisión del IFSP
              pueden no recuperarse después de que comience la nueva página de
              servicio. En esta situación, hable con su coordinador de servicios
              para solicitar servicios compensatorios para las sesiones
              perdidas.
            </span>
          </label>
        </p>

        <h3 style={styles.h3Tag}>9. Ubicación de los Servicios: </h3>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Los servicios deben ocurrir en la ubicación especificada en el
              IFSP en la página de servicio de esa disciplina.
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              En ocasiones, si se especifica que el servicio se lleve a cabo en
              el hogar, el profesional puede ver al niño en una guardería. De
              manera similar, si la página de servicio especifica la ubicación
              como guardería, el profesional puede realizar una sesión en casa.
              Sin embargo, si el cambio en la ubicación será a largo plazo,
              entonces se debe escribir una nueva página de servicio para
              reflejar la nueva ubicación.
            </span>
          </label>
        </p>

        <div className="html2pdf__page-break"></div>

        <h3 style={styles.h3Tag}>10. Disposición de la Sesión: </h3>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Cada sesión puede durar solo lo que la página de servicio del IFSP
              permita.{" "}
            </span>
          </label>
        </p>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              La sesión se compone de la interacción con el niño, la discusión
              con el padre / cuidador, la obtención de la firma del padre /
              cuidador y la escritura de las notas clínicas (ver más abajo).
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              {" "}
              La discusión con familiares o guardianes es una parte integral de
              la sesión de Intervención Temprana y se incluye en el tiempo de
              duración del servicio. Los terapeutas facturarán el tiempo
              dedicado a la discusión durante la sesión.{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              El tiempo dedicado a documentar el servicio del día y obtener una
              firma en el registro de asistencia se incluye en el tiempo de
              duración del servicio. No hay una cantidad determinada de tiempo
              que se supone que se debe gastar en escribir las notas; más bien
              depende del practicante individual y la sesión.
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Si un padre llega tarde a una sesión, el tiempo puede ser deducido
              de la sesión ya que el profesional puede tener un horario para
              cumplir.{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Ni un padre ni un profesional pueden cambiar la frecuencia y / o
              la duración de las sesiones del IFSP, incluso si es mejor para el
              horario de la familia (por ejemplo, decidir hacer dos horas de
              terapia a la vez en lugar de hacerlo una hora dos veces a la
              semana). Si un IFSP no cumple con las necesidades de la familia,
              la familia debe comunicarse con TA o con el coordinador de
              servicios para cambiar el papeleo.
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Un profesional no esta permitido dar servicio después de la fecha
              de finalización de la página de servicio IFSP del niño. Se debe
              escribir un IFSP actualizado en una reunión de revisión para que
              los servicios continúen.{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              No se puede dar servicios al cumplir el tercer cumpleaños del niño
              y en adelante.
            </span>
          </label>
        </p>

        <div className="html2pdf__page-break"></div>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              No se proporcionan servicios mientras la cuenta de una familia ha
              sido suspendida hasta que la cuenta haya sido restablecida y las
              páginas de nuevos servicios estén escritas y firmadas por el
              padre.{" "}
            </span>
          </label>
        </p>

        <h3 style={styles.h3Tag}>11. Documentación: </h3>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Formulario de verificación de encuentro de servicio (SEVF).
              Después de cada sesión, el profesional le presentará a usted (o al
              proveedor de cuidado ) un SEVF, que es un registro de asistencia,
              para que lo firme. Esto puede ser en papel o electrónico, desde la
              página web de TA. El profesional debe haber llenado la fecha, la
              ubicación, la hora de inicio y la hora de finalización antes de
              solicitar la firma. Las horas totales se calcularán y se agregarán
              en un momento posterior.{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              La firma del formulario es un testimonio y acuerdo de que el
              profesional de hecho brindó el servicio requerido en las fechas y
              horas especificadas.
            </span>
          </label>
        </p>

        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Si se cometió un error en el formulario de papel, el profesional
              le pedirá que inicie los errores y correcciones.{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              El practicante puede facturar solo por el tiempo que pase en la
              sesión. Si una sesión se corta debido a la tardanza u otra razón,
              el tiempo facturado debe reflejar solo el tiempo empleado en la
              sesión (incluido el tiempo de toma de notas y el tiempo de
              discusión).
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              El practicante también escribirá una nota clínica en el portal
              online. La nota clínica debe contener un resumen de las
              actividades realizadas y recomendaciones de estrategias y
              actividades para que las use en casa con su hijo. Esta nota se
              completará durante el tiempo de la sesión del IFSP.{" "}
            </span>
          </label>
        </p>
        <p>
          <label style={styles.checkboxContainer}>
            <span style={styles.checkboxLabel}>
              Si un profesional no presenta un SEVF para firmar, o si tiene
              alguna otra pregunta sobre cómo documentar las sesiones
              correctamente, llame a la oficina de Therapy Associates.{" "}
            </span>
          </label>
        </p>

        <div className="html2pdf__page-break"></div>

        <p>
          Estamos deseando trabajar con usted y esperamos que usted y su hijo
          tengan una relación educativa y productiva con nuestros profesionales.
        </p>
      </div>
    </div>
  );
}

export default SpanishDocs;
