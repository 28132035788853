import React from 'react'

function LastUpdatedTime(props) {
  return (
    <>
        <div className='position-fixed bottom-0 end-0 p-3 d-none d-lg-block' style={{color:'#666666'}}>
            <p className='mb-0'>Last refreshed : {props.time}</p>
        </div>
    </>
  )
}

export default LastUpdatedTime
