import React, { useEffect, useState } from "react";
import axios from "axios";
import { Cookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APIEndpoints } from "./APIEndpoints";

const ResetPasswordOtpVerify = (props) => {
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const cookie = new Cookies();
    const nav = useNavigate();
    const [code, setcode] = useState();
    const user_id = location?.state?.user_id;

    useEffect(() => {
        const user_id = location?.state?.user_id;
        if (user_id === undefined || user_id === null) {
            nav("/reset-password");
        }
    }, []);

    useEffect(() => {
        const data = cookie.get("logindata");
        if (data) {
            nav("/");
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true); // Start loading when the form is submitted

        axios
            .post(APIEndpoints.VERIFY_RESET_CODE, {
                user_id: user_id,
                code: code
            })
            .then((res) => {
                const response_data = res.data
                if (response_data?.status === "success") {
                    setcode('');
                    toast.success(response_data.message, { position: toast.POSITION.TOP_RIGHT });
                    setTimeout(() => {
                        nav("/reset-password-confirm", {
                            state: {
                                user_id: user_id,
                                verified: response_data.verified
                            }
                        });
                    }, 2000);
                }else{
                    toast.error(response_data.error, { position: toast.POSITION.TOP_RIGHT }); 
                }
            })
            .catch((error) => {
                setcode('');
                toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT });
            })
            .finally(() => {
                setLoading(false); // Stop loading after the request is complete
            });
    };

    return (
        <>
            <section className="LoginPage">
                <div className="d-flex justify-content-center text-center">
                    <div className="col-5 mt-2" >
                        <img
                            src="/Therapy Associates_Logo.png"
                            alt="logo"
                            style={{ maxWidth: "180px" }}
                            onClick={() => nav('/login')}
                        />
                    </div>
                </div>
                <hr />
                <div className="container-fluid h-custom">
                    <div className="row d-flex justify-content-center align-items-center mx-2">
                        <div className="col-md-8 col-lg-6 col-xl-4 mt-4" style={{ padding: '2rem', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px' }}>
                            <form onSubmit={handleSubmit}>
                                {/* Email input  */}
                                <div className="form-outline mb-4 text-start">
                                    <label className="form-label" htmlFor="form3Example3">
                                        Verification Code
                                    </label>
                                    <input
                                        type="number"
                                        id="form3Example3"
                                        className="form-control form-control"
                                        placeholder="Enter code here"
                                        value={code}
                                        onChange={(e) => {
                                            setcode(e.target.value);
                                        }}
                                        required
                                    />
                                </div>


                                <div className="text-center mt-4">
                                    {/* Show the login button or the loader based on the loading state */}
                                    {loading ? (
                                        <div className="spinner-border text-dark" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    ) : (
                                        <button type="submit" className="btn btn-dark">
                                            Verify
                                        </button>
                                    )}

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer style={{ top: '7rem', position: 'fixed' }} />
        </>
    );
};

export default ResetPasswordOtpVerify;

