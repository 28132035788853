import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import { APIEndpoints } from "./APIEndpoints";
import {
  setLocalStorageWithExpiration,
  getLocalStorageWithExpiration,
} from "./localStorageUtil";
import SignatureCanvas from "react-signature-canvas";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Billing() {
  const navigate = useNavigate();
  const cookie = new Cookies();

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    if (getLocalStorageWithExpiration("task-data")) {
      setIsLoading(true);
      const cookieData = getLocalStorageWithExpiration("task-data");
      setData(cookieData);
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const token = cookie.get("logindata");
        const { sf_id } = token;
        const response = await axios.get(`${APIEndpoints.ALL_TASK}${sf_id}`);
        setData(response.data);
        setLocalStorageWithExpiration("task-data", response.data, 30); // Store data for 30 minutes
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const [allSelected, setAllSelected] = useState(false);

  const handleSelectAll = () => {
    setSelectedItems((prevSelectedItems) => {
      if (allSelected) {
        // If all items are selected, deselect them all
        setAllSelected(false);
        return [];
      } else {
        // If not all items are selected, select them all
        const allItems = [];
        // const clickedTasks = cookie.get("clickedTasks");
        const clickedTasks = cookie.get("clickedTasks") ? cookie.get("clickedTasks") : [];

        Object.keys(data.billable).forEach((key) => {
          const item = data.billable[key];
          item.sessions.forEach((session) => {
            if (!clickedTasks.includes(session.childId)) {
              allItems.push({
                childId: session.childId,
                therapistId: session.therapist,
                therapistServiceId: session.therapist_service,
                yearMonth: session.formattedDate,
                childName: item.childName,
              });
            }
          });
        });

        setAllSelected(true);
        return allItems;
      }
    });
  };

  console.log(selectedItems);

  const handleCheckboxChange = (index) => {
    const item = data.billable[filterBillableItems()[index]];

    setSelectedItems((prevSelectedItems) => {
      // Find if any item matches both therapistServiceId and childName
      const isSelected = prevSelectedItems.some(
        (selectedItem) => selectedItem.childId === item.childId
      );

      if (isSelected) {
        // If already selected, remove all its sessions from the list
        return prevSelectedItems.filter(
          (selectedItem) => !(selectedItem.childId === item.childId)
        );
      } else {
        // If not selected, add all its sessions to the list
        return [
          ...prevSelectedItems,
          ...item.sessions.map((s) => ({
            childId: s.childId,
            therapistId: s.therapist,
            therapistServiceId: s.therapist_service,
            yearMonth: s.formattedDate,
            childName: item.childName, // Include childName in the added item
          })),
        ];
      }
    });
  };

  // LOGIC FOR SIGNATURE PAD

  const [signatureData, setSignatureData] = useState(null);

  const [file, setFile] = useState();

  const signatureCanvasRef = useRef(null);

  const base64ToFile = (base64, filename) => {
    const arr = base64.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const clearSignature = () => {
    signatureCanvasRef.current.clear();
    setSignatureData(null);
    setFile(null);
  };

  const saveSignature = () => {
    const signatureImage = signatureCanvasRef.current.toDataURL();
    setSignatureData(signatureImage);

    const file = base64ToFile(signatureImage, "signature.png");
    setFile(file);
  };

  const handleSignUpload = async () => {
    try {
      if (!file) {
        toast.error("Please upload signature first,", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }

      if (selectedItems.length === 0) {
        toast.error("Please select child first,", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }

      setLoading(true);
      const token = cookie.get("logindata");
      const { access_token, sf_id } = token;
      const formData = new FormData();
      formData.append("photo", file);
      formData.append("session", JSON.stringify(selectedItems));

      // Make the POST request to the API
      const response = await fetch(APIEndpoints.BILLABLE_FORM_SUBMIT, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        body: formData,
      });

      if (response.status === 200) {
        // Handle the response from the API
        const data = await response.json();
        console.log(data, "response data");
        setLoading(false);

        // Disable for 5 min
        const expiration = new Date(new Date().getTime() + 5 * 60 * 1000);
        let clickedTasks = cookie.get("clickedTasks");
        console.log(clickedTasks);

        clickedTasks = clickedTasks ? clickedTasks : [];

        const uniqueChildIds = new Set(clickedTasks);

        console.log(uniqueChildIds);

        selectedItems.forEach((item) => {
          uniqueChildIds.add(item.childId);
        });

        const updatedClickedTasks = Array.from(uniqueChildIds);

        cookie.set("clickedTasks", JSON.stringify(updatedClickedTasks), {
          path: "/",
          expires: expiration,
        });

        // toast.success("Successfully submitted. We will email shortly.", {
        //   position: toast.POSITION.TOP_RIGHT,
        //   autoClose: 2000,
        // });

        setTimeout(() => {
          navigate("/billing/confirmed");
        }, 2000);
      } else if (response.status === 204) {
        setLoading(false);
        toast.error("No service.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        setLoading(false);
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Error in Sign Upload", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("Error in Sign Upload.");
    }
  };

  const filterBillableItems = () => {
    const clickedTasks = cookie.get("clickedTasks");
    const clickedChildIds = clickedTasks ? clickedTasks : [];

    return Object.keys(data.billable).filter(
      (key) => !clickedChildIds.includes(data.billable[key].childId)
    );
  };

  // const countBillableItems = () => {
  //   const clickedTasks = cookie.get("clickedTasks");
  //   const clickedChildIds = clickedTasks ? clickedTasks : [];

  //   // Ensure data.billable is an object before proceeding
  //   if (!data.billable || typeof data.billable !== "object") {
  //     return 0; // Return 0 if billable data is not available
  //   }

  //   return Object.keys(data.billable).reduce(
  //     (count, key) =>
  //       !clickedChildIds.includes(data.billable[key].childId)
  //         ? count + 1
  //         : count,
  //     0
  //   );
  // };

  // console.log(countBillableItems(), "%%%%");

  return (
    <>
      <Header />
      <ToastContainer style={{ top: "7rem", position: "fixed" }} />
      {isLoading ? (
        <div className="card-body text-center">
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="container custom-container my-5">
          <div className="">
            <div className="my-3">
              <p>
                <strong>Confirm your billing</strong>
              </p>
              <p className="fs-6">
                Please select the child services you wish to confirm for billing
                below. Then, scroll to the bottom of the page to sign. Only
                sessions with parent signature and fully reconciled with the
                EIMS are available for billing confirmation.
              </p>
            </div>
            {data.billable && Object.keys(data.billable).length > 0 && (
              <div className="my-3">
                <button
                  className="btn btn-sm btn-dark"
                  onClick={() => {
                    handleSelectAll();
                  }}
                >
                  {allSelected ? "Unselect All" : "Select All"}
                </button>
              </div>
            )}
            {data.billable && Object.keys(data.billable).length > 0 ? (
              filterBillableItems().map((key, index) => {
                const item = data.billable[key];
                const isChecked = selectedItems.some(
                  (selectedItem) =>
                    selectedItem.childId === item.childId &&
                    selectedItem.childName === item.childName
                );

                return (
                  <div key={index} className="row p-0 m-0 ">
                    <div className="col-1 col-lg-1  d-flex justify-content-center">
                      <input
                        // className="w-auto"
                        style={{ width: "1rem" }}
                        type="checkbox"
                        onChange={() => handleCheckboxChange(index)}
                        checked={isChecked}
                      />
                    </div>
                    <div className="box d-flex flex-column p-3 mb-3 rounded col-11 col-lg-11">
                      <div
                        className="d-flex justify-content-between"
                        aria-expanded={openIndex === index}
                        aria-controls={`collapse${index}`}
                        onClick={() => toggleAccordion(index)}
                      >
                        <div>
                          <h5>
                            <strong>
                              {item.childName}: $
                              {item.total_amount.toLocaleString()}
                            </strong>
                          </h5>
                          <p>
                            {item.total_sessions}{" "}
                            {item.total_sessions === 1 ? "Session" : "Sessions"}
                            , {item.totalSessionTime}{" "}
                            {item.totalSessionTime <= 1 ? "Hour" : "Hours"}
                          </p>
                          <p>Avg. Hourly Rate: ${item.averageTimeRate}</p>
                        </div>
                        <div>
                          <i
                            className={`fa ${
                              openIndex === index
                                ? "fa-minus-circle"
                                : "fa-plus-circle"
                            }`}
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={() => toggleAccordion(index)}
                          ></i>
                        </div>
                      </div>
                      <div
                        id={`collapse${index}`}
                        className={`accordion-collapse collapse ${
                          openIndex === index ? "show" : ""
                        } table-responsive`}
                        aria-labelledby={`heading${index}`}
                        data-bs-parent="#accordionExample"
                      >
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Service</th>
                              <th>Hours</th>
                              <th>Rate</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.sessions.map((s, sessionIndex) => (
                              <tr key={sessionIndex}>
                                <td>
                                  {s.service_completed.replace(/-/g, "/")}
                                </td>
                                <td>{s.service_type.split("-")[1]}</td>
                                <td>{s.session_time}</td>
                                <td>${s.pay_rate}</td>
                                <td>${s.total_amount.toLocaleString()}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="text-center">There is nothing to process</p>
            )}
          </div>
          <hr />
          <div>
            <div className="text-center">
              <h6>
                <b>
                  I certify that the above services were provided in accordance
                  with the child's IFSP.
                </b>
              </h6>
            </div>
            <div className="row position-relative">
              <div
                className="mb-3"
                style={{
                  background: "rgb(248, 248, 248)",
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    textAlign: "center",
                  }}
                >
                  <a
                    className="position-absolute signature-erase "
                    onClick={clearSignature}
                  >
                    <i className="fa-solid fa-eraser"></i>
                  </a>
                  <SignatureCanvas
                    ref={signatureCanvasRef}
                    penColor="black"
                    canvasProps={{
                      width: 500,
                      height: 200,
                      className: "signatureCanvas",
                    }}
                    onEnd={saveSignature}
                  />

                  {signatureData && (
                    <div className="text-center mt-3">
                      <img
                        src={signatureData}
                        style={{
                          width: "30%",
                          border: "1px solid black",
                          background: "white",
                        }}
                        alt="signature Image"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="text-center mb-3">
              <button
                className="btn btn-dark"
                onClick={() => {
                  handleSignUpload();
                }}
              >
                {loading ? (
                  <span>
                    Submiting...{" "}
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    ></div>
                  </span>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Billing;
