import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Cookies from 'universal-cookie';
import { APIEndpoints } from './APIEndpoints';
import axios from 'axios';
import { setLocalStorageWithExpiration, getLocalStorageWithExpiration } from './localStorageUtil';



function Form25Task() {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [formTask, setFormTask] = useState([])


    const fetchData = async () => {
      if (getLocalStorageWithExpiration('task-data')) {
        setIsLoading(true);
        const cookieData = getLocalStorageWithExpiration('task-data');
        setFormTask(cookieData.form25task);
        setIsLoading(false);
      } else {
        try {
          setIsLoading(true);
          const cookie = new Cookies();
          const token = cookie.get('logindata');
          const { access_token, sf_id } = token;
          const response = await axios.get(
            `${APIEndpoints.ALL_TASK}${sf_id}`
          );
          setFormTask(response.data.form25task);
          // Set Data in Cookie for 30 minutes
          setLocalStorageWithExpiration('task-data', response.data, 30);
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        }
      }
    };
  
    useEffect(() => {
      fetchData();
    }, []);

    return (
      <>
        <Header />
        {isLoading ? (
          <div className="container-fluid custom-container my-5">
            <div className="container-fluid my-5 h-100">
              <div className="d-flex justify-content-center align-items-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {formTask && formTask.length > 0 ? (
              <div className="container custom-container my-5">
                {formTask.map((task, index) => (
                  <div
                    key={index}
                    className="box d-flex justify-content-center p-3 mb-3 rounded"
                    // onClick={(e) => {
                    //     navigate('/form-25', {
                    //       state: { name: task.child_name.split('-')[0], service_id: task.child_service_id, task_id: task.id, therapist_service_id: task.therapist_service_id
                    //       },
                    //     });
                    //   }}
                    onClick={(e) => {
                      navigate("/download-form25");
                    }}
                  >
                    <div className="m-auto">
                      <h5 style={{ fontWeight: "500" }}>
                        {task.child_name.split("-")[0]}
                      </h5>
                    </div>
                    <div className="">
                      <i className="fa-solid fa-arrow-right"></i>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="container custom-container my-5">
                <div className="text-center">No Form 25 tasks to show</div>
              </div>
            )}
          </>
        )}
      </>
    );
}

export default Form25Task;

