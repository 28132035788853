import React, { useEffect, useState, useRef } from "react";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import { Cookies } from "react-cookie";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { APIEndpoints } from "./APIEndpoints";
import {
  getLocalStorageWithExpiration,
  removeLocalStorageItem,
} from "./localStorageUtil";
import SignatureCanvas from "react-signature-canvas";

function EIMSForm(props) {
  const [isloading, setIsLoading] = useState(false);

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);

  const [isCancellation, setIsCancellation] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  const [name, setname] = useState(location?.state?.name);
  const [serviceSpecialty, setSpecialty] = useState(
    location?.state?.serviceSpecialty
  );
  const [serviceId, setServiceId] = useState(location?.state?.service_id);
  const [childID, setChildId] = useState(location?.state?.childID);
  const [therapistServiceId, setTherapistServiceId] = useState(
    location?.state?.therapistServiceId
  );
  const [serviceLength, setServiceLength] = useState(
    location?.state?.serviceLength
  );
  const [assignedDate, setAssignedDate] = useState(
    location?.state?.assignedDate
  );
  const [policySigned, setPolicySigned] = useState(
    location?.state?.policySigned
  );
  const [policyDateTime, setPolicyDateTime] = useState(
    location?.state?.policy_datetime
  );

  const [sessionId, setSessionId] = useState();

  const cookie = new Cookies();
  const token = cookie.get("logindata");

  useEffect(() => {
    if (
      !assignedDate ||
      !therapistServiceId ||
      !childID ||
      !serviceId ||
      !serviceSpecialty ||
      !name
    ) {
      navigate("/");
    }
  }, [
    serviceLength,
    assignedDate,
    policySigned,
    therapistServiceId,
    childID,
    serviceId,
    serviceSpecialty,
    name,
    navigate,
  ]);

  // LOGIC FOR SIGNATURE PAD

  const [signatureData, setSignatureData] = useState(null);

  const signatureCanvasRef = useRef(null);

  const base64ToFile = (base64, filename) => {
    const arr = base64.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const clearSignature = () => {
    signatureCanvasRef.current.clear();
    setSignatureData(null);
    setFile(null);
  };

  const saveSignature = () => {
    const signatureImage = signatureCanvasRef.current.toDataURL();
    setSignatureData(signatureImage);

    const file = base64ToFile(signatureImage, "signature.png");
    setFile(file);
  };

  const [file, setFile] = useState();

  const divRef = useRef();

  const showRef = useRef();

  const [serviceStatus, setServiceStatus] = useState("");
  const [serviceType, setServiceType] = useState("");

  const handleServiceStatusChange = (e) => {
    const selectedServiceStatus = e.target.value;
    // Update the service status
    setServiceStatus(selectedServiceStatus);

    // Check if the selected service status requires a specific service type
    if (
      [
        "1 - Ongoing IFSP Service",
        "4 - Make-up Service Provided",
        "5 - Compensatory Service Provided",
      ].includes(selectedServiceStatus)
    ) {
      setIsCancellation(false);
      // Set the service type to 'OT - Occupational Therapy' and disable the service type dropdown
      if (serviceSpecialty == "Occupational Therapy") {
        setServiceType("OT - Occupational Therapy");
      } else if (serviceSpecialty == "Physical Therapy") {
        setServiceType("PT - Physical Therapy");
      } else if (serviceSpecialty == "Speech Therapy") {
        setServiceType("SLP - Speech/Language Therapy");
      } else if (serviceSpecialty == "Development Intervention") {
        setServiceType("DI - Developmental Intervention");
      } else if (serviceSpecialty == "Social Work") {
        setServiceType("SW - Social Worker");
      } else if (serviceSpecialty == "Translator") {
        setServiceType("I/T - Interpreter/Translator");
      }
    } else if (
      [
        "3 - Family Missed/Cancelled (inc weather related)",
        "2 - Practitioner Missed/Cancelled (inc weather related)",
      ].includes(selectedServiceStatus)
    ) {
      setIsCancellation(true);
    } else {
      // Provide options for service type and enable the dropdown
      setIsCancellation(false);
      setServiceType("");
    }
  };

  const handleSignUpload = async (sessionId) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      const cookie = new Cookies();
      const token = cookie.get("logindata");
      const { access_token } = token;
      formData.append("session_id", sessionId);
      formData.append("photo", file);

      // Make the POST request to the API
      const response = await fetch(APIEndpoints.ADD_SIGNATURE, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        body: formData,
      });

      // Handle the response from the API
      const data = await response.json();

      setIsLoading(false);

      toast.success("Successfully submitted.", {
        position: toast.POSITION.TOP_RIGHT,
      }); // Fixed the typo here
      if (policySigned) {
        setTimeout(() => {
          navigate("/session-details", {
            state: {
              name: name,
              sessionId: sessionId,
            },
          });
        }, 2000);
      } else {
        setTimeout(() => {
          navigate("/select-contact", {
            state: {
              childID: childID,
            },
          });
        }, 5000);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Error in Sign Upload", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("Error in Sign Upload.");
    }
  };

  // Create cancellation object
  const handleCancellation = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    const cookie = new Cookies();
    const token = cookie.get("logindata");
    const { access_token, sf_id } = token;
    let type = "";

    if (serviceStatus === "3 - Family Missed/Cancelled (inc weather related)") {
      type = "Family Cancelled";
    }
    if (
      serviceStatus ===
      "2 - Practitioner Missed/Cancelled (inc weather related)"
    ) {
      type = "Therapist Cancelled";
    }

    const requestBody = {
      therapist_service_id: therapistServiceId,
      therapist_id: sf_id,
      date: formData.get("completed_date"),
      note: formData.get("note"),
      cancel_type: type,
      child_id: childID,
    };

    try {
      setIsLoading(true);
      const response = await fetch(APIEndpoints.CREATE_SESSION_CANCELLATION, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (response.status === 200) {
        event.target.reset();
        toast.success(data?.message, { position: toast.POSITION.TOP_RIGHT });
      } else {
        toast.error(data?.message, { position: toast.POSITION.TOP_RIGHT });
      }

      setIsLoading(false);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      toast.error("Raise Some Error", { position: toast.POSITION.TOP_RIGHT });
      setTimeout(() => {
        navigate("/");
      }, 2000);
      console.error("Error:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isCheckboxChecked && !file) {
      toast.error("Please upload the signature before submitting.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (serviceId === null || serviceId === undefined || serviceId === "") {
      toast.error("Please process from home page.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        navigate("/");
      }, 2000);
      return;
    }

    const cookie = new Cookies();
    const token = cookie.get("logindata");
    const { access_token, sf_id } = token;

    const formData = new FormData(event.target);

    const requestBody = {
      child_id: childID,
      therapist_service: therapistServiceId,
      therapist_id: sf_id,
      completed_date: formData.get("completed_date"),
      session_location_codes: formData.get("session_location_codes"),
      session_status: serviceStatus,
      session_type_code: serviceType,
      start_time: formData.get("start_time"),
      end_time: formData.get("end_time"),
      responsible_party_available: isCheckboxChecked,
      note: formData.get("note"),
    };

    if (formData.get("completed_date") < assignedDate) {
      toast.error("Cannot log a session before assigned date.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setServiceDateInput(getTodayDate());
      return;
    }

    // Service date condition
    if (formData.get("completed_date") < getMinDate()) {
      toast.error("Cannot log a session that's over 60 days ago.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setServiceDateInput(getTodayDate());
      return;
    }

    // Service date condition for future dates
    if (formData.get("completed_date") < getMinDate()) {
      toast.error("Cannot log a future session.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setServiceDateInput(getTodayDate());
      return;
    }

    // check differnce between time should be equal to session length
    const maxTeamMeetingLength = 180; // 3 hours in minutes

    if (serviceStatus === "6 - Team Meeting") {
      if (
        getDifferenceBetweenMinutes(startTime, endTime) > maxTeamMeetingLength
      ) {
        setEndTime("");
        toast.error(
          `The difference between start time and end time should be less than or equal ${maxTeamMeetingLength} minutes for a Team Meeting.`,
          { position: toast.POSITION.TOP_RIGHT }
        );
        return;
      }
    } else {
      if (getDifferenceBetweenMinutes(startTime, endTime) > serviceLength) {
        setEndTime("");
        setStartTime("");
        toast.error(
          `The difference between start time and end time should be less than or equal to ${serviceLength} minutes.`,
          { position: toast.POSITION.TOP_RIGHT }
        );
        return;
      }
    }

    try {
      setIsLoading(true);
      const response = await fetch(
        APIEndpoints.CREATE_SESSION + `${serviceId}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();

      if (response.status === 200) {
        setSessionId(data.session_id);
        event.target.reset();
        setIsLoading(false);

        // Update billable data in storage
        if (getLocalStorageWithExpiration("billableData")) {
          removeLocalStorageItem("billableData", 0);
        }

        if (isCheckboxChecked && file) {
          handleSignUpload(data.session_id);
        } else {
          toast.success("Successfully submitted.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          if (policySigned) {
            setTimeout(() => {
              navigate("/session-details", {
                state: {
                  name: name,
                  sessionId: data.session_id,
                },
              });
            }, 2000);
          } else {
            setTimeout(() => {
              navigate("/select-contact", {
                state: {
                  childID: childID,
                },
              });
            }, 1000);
          }
        }
      } else {
        try {
          if (data?.status === "error") {
            const errorMessageString = data?.msg;
            const jsonStartIndex = errorMessageString.indexOf("[{");
            const jsonEndIndex = errorMessageString.lastIndexOf("}]") + 2;
            const jsonString = errorMessageString.substring(
              jsonStartIndex,
              jsonEndIndex
            );
            const regex = /'message': '(.*?)'/;
            const match = regex.exec(jsonString);

            if (match && match.length > 1) {
              const errorMessage = match[1];
              if (errorMessage.includes("pay profile")) {
                toast.error(
                  "Please reach out to your case manager, your profile needs an update before submitting",
                  { position: toast.POSITION.TOP_RIGHT }
                );
              }
            } else {
              console.log("Message not found in the string.");
            }
            setIsLoading(false);
            setTimeout(() => {
              navigate("/");
            }, 3000);
          }else{
            toast.error(data, { position: toast.POSITION.TOP_RIGHT });
            setIsLoading(false);
          }
        } catch {
          toast.error(data, { position: toast.POSITION.TOP_RIGHT });
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Raise Some Error", { position: toast.POSITION.TOP_RIGHT });
      console.error("Error:", error);
    }
  };

  // Get Today to set max limit in  service date
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  };

  // Set minimum date to 60 days ago in service date
  const getMinDate = () => {
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 60);
    const year = minDate.getFullYear();
    const month = String(minDate.getMonth() + 1).padStart(2, "0");
    const day = String(minDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [currentTime, setCurrentTime] = useState(getCurrentTime()); // Get the current time

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(getCurrentTime()); // Update the current time every minute
    }, 60000); // Update every minute

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  // Function to get the current time in "HH:mm" format
  function getCurrentTime() {
    const today = new Date();
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes}`;
  }

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [serviceDateInput, setServiceDateInput] = useState(getTodayDate());

  const handleClear = () => {
    setEndTime("");
    document.getElementById("serviceEndTime").value = "";
  };

  const handleStartClear = () => {
    setStartTime("");
    document.getElementById("serviceStartTime").value = "";
  };

  const [userTimezone, setUserTimezone] = useState("");
  const [currentLocallTime, setCurrentLocalTime] = useState("");

  const StartTimeBox = document.getElementById("startTime");
  const EndTimeBox = document.getElementById("endTime");

  useEffect(() => {
    // Get the user's timezone
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setUserTimezone(userTimezone);

    // Get the current date and time in the user's timezone
    const options = { hour: "2-digit", minute: "2-digit" };
    const currentLocalTime = new Date().toLocaleTimeString([], options);
    setCurrentLocalTime(currentLocalTime);
  }, []);

  const handleServiceDateChange = (event) => {
    const s_date = event.target.value;

    if (isCancellation) {
      setServiceDateInput(s_date);
      return;
    }

    // On change show time boxes
    StartTimeBox.style.setProperty("display", "flex", "important");
    EndTimeBox.style.setProperty("display", "flex", "important");

    if (s_date < assignedDate) {
      toast.error("Cannot log a session before assigned date.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setServiceDateInput("");
      // hide time
      StartTimeBox.style.setProperty("display", "none", "important");
      EndTimeBox.style.setProperty("display", "none", "important");
      return;
    }

    if (s_date < getMinDate()) {
      toast.error("Cannot log a session that's over 60 days ago.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    // Therapist Import date condition
    const { import_date } = token;
    if (s_date < import_date) {
      toast.error("Cannot log a session that's over import date.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setServiceDateInput(s_date);
    if (s_date == getTodayDate()) {
      setIsCheckboxChecked(true);
      var checkbox = document.getElementById("checkbox-eims");
      checkbox.style.display = "block";
    } else {
      setIsCheckboxChecked(false);
      var checkbox = document.getElementById("checkbox-eims");
      checkbox.style.display = "none";
      toast.error(
        "Can't capture parent signature more than 30 minutes after session",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const [selectTime, setSelectTime] = useState(new Date().getTime());

  const [startTimeAleart, setStartTimeAleart] = useState("");
  const [endTimeAleart, setEndTimeAleart] = useState("");

  const handleStartTimeChange = (event) => {
    console.log(event.target);
    setStartTimeAleart("");
    setEndTimeAleart("");
    const newStartTime = event.target.value;

    const twentyFourHourStartTime = convert12HourTo24Hour(newStartTime);

    var currentDate = new Date();
    currentDate.setHours(twentyFourHourStartTime.split(":")[0]);
    currentDate.setMinutes(twentyFourHourStartTime.split(":")[1]);
    const time = new Date(currentDate).getTime();
    setSelectTime(time);

    if (serviceDateInput == "") {
      // toast.error("Select service date first.", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      setStartTimeAleart("Select service date first.");
      return;
    }

    // check differnce between time should be equal to session length
    if (endTime !== "") {
      handleClear();
      setStartTimeAleart("Select Start time then End time.");
      if (
        !(getDifferenceBetweenMinutes(newStartTime, endTime) <= serviceLength)
      ) {
        setEndTime("");
        setStartTime("");
        // toast.error(
        //   `The difference between start time and end time should be less than or equal to ${serviceLength} minutes.`,
        //   { position: toast.POSITION.TOP_RIGHT }
        // );
        setStartTimeAleart(
          `The difference between start time and end time should be less than or equal to ${serviceLength} minutes.`
        );
        return;
      }
    }

    setStartTime(newStartTime);
  };

  function convert12HourTo24Hour(timeString) {
    const [time, period] = timeString.split(" ");
    const [hours, minutes] = time.split(":").map((part) => parseInt(part, 10));

    if (isNaN(hours) || isNaN(minutes)) {
      // Invalid time format
      return "Invalid Time";
    }

    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }

  function getDifferenceBetweenMinutes(startTime, endTime) {
    const splitStartTime = startTime.split(":").map(Number);
    const splitEndTime = endTime.split(":").map(Number);

    const startTimeInMinutes = splitStartTime[0] * 60 + splitStartTime[1];
    const endTimeInMinutes = splitEndTime[0] * 60 + splitEndTime[1];

    const timeDifference = endTimeInMinutes - startTimeInMinutes;
    return timeDifference;
  }

  const handleEndTimeChange = (event) => {
    console.log("call");
    setEndTimeAleart("");
    setStartTimeAleart("");
    const newEndTime = event.target.value;

    if (serviceDateInput === "") {
      // toast.error("Select service date first.", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      setEndTimeAleart("Select service date first.");
      return;
    }

    if (startTime === "") {
      // toast.error("Select start time first.", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      setEndTimeAleart("Select start time first.");
      return;
    }

    const twentyFourHourEndTimeNew = convert12HourTo24Hour(newEndTime);
    console.log(twentyFourHourEndTimeNew);
    var currentDate = new Date();
    currentDate.setHours(twentyFourHourEndTimeNew.split(":")[0]);
    currentDate.setMinutes(twentyFourHourEndTimeNew.split(":")[1]);
    const time = new Date(currentDate).getTime();

    console.log(time - selectTime);

    if (time - selectTime < 1000 * 60 * 8) {
      // toast.error(
      //   "Difference between start time and end time should be greater than 8 minutes.",
      //   {
      //     position: toast.POSITION.TOP_RIGHT,
      //   }
      // );
      setEndTimeAleart(
        "Difference between start time and end time should be greater than 8 minutes."
      );
      return;
    }

    const currentTime = new Date(); // Get the current time

    // Calculate 30 minutes less than current time
    const thirtyMinutesAgo = new Date(currentTime);
    thirtyMinutesAgo.setMinutes(currentTime.getMinutes() - 30);

    const thirtyMinutesAgoStr = `${thirtyMinutesAgo
      .getHours()
      .toString()
      .padStart(2, "0")}:${thirtyMinutesAgo
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;

    const twentyFourHourEndTime = convert12HourTo24Hour(newEndTime);
    const twentyFourHourStartTime = convert12HourTo24Hour(startTime);
    // const twentyFourHourCurrentTime = `${currentTime
    //   .getHours()
    //   .toString()
    //   .padStart(2, "0")}:${currentTime
    //   .getMinutes()
    //   .toString()
    //   .padStart(2, "0")}`;
    const twentyFourHourCurrentTime = `${currentTime
      .getHours()
      .toString()
      .padStart(2, "0")}:${(currentTime.getMinutes() + 16)
      .toString()
      .padStart(2, "0")}`;

    if (twentyFourHourEndTime <= twentyFourHourStartTime) {
      // toast.error("End time should be greater than start time.", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      setEndTimeAleart("End time should be greater than start time.");
      return;
    }

    // const startTime = startTime.split(":")[1];
    // const endMin = newEndTime.split(":")[1];
    // console.log(startTime, newEndTime, 'l');

    const getTodayDate = () => {
      const today = new Date();
      return `${today.getFullYear()}-${(today.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
    };

    // if (
    //   serviceDateInput === getTodayDate() &&
    //   twentyFourHourEndTime >= twentyFourHourCurrentTime
    // ) {
    //   toast.error(
    //     "End time should be less than current time plus 15 minutes.",
    //     {
    //       position: toast.POSITION.TOP_RIGHT,
    //     }
    //   );
    //   return;
    // }

    if (serviceDateInput === getTodayDate()) {
      if (twentyFourHourEndTime <= thirtyMinutesAgoStr) {
        toast.error(
          "Can't capture parent signature more than 30 minutes after session",
          { position: toast.POSITION.TOP_RIGHT }
        );
        setEndTime(newEndTime);
        setIsCheckboxChecked(false);
        var checkbox = document.getElementById("checkbox-eims");
        checkbox.style.display = "none";
      } else {
        setEndTime(newEndTime);
        setIsCheckboxChecked(true);
        var checkbox = document.getElementById("checkbox-eims");
        checkbox.style.display = "block";
      }
    } else {
      setEndTime(newEndTime);
      setIsCheckboxChecked(false);
      var checkbox = document.getElementById("checkbox-eims");
      checkbox.style.display = "none";
    }

    // check difference between time should be equal to session length
    const maxTeamMeetingLength = 180; // 3 hours in minutes

    if (serviceStatus === "6 - Team Meeting") {
      if (
        getDifferenceBetweenMinutes(startTime, newEndTime) >
        maxTeamMeetingLength
      ) {
        setEndTime("");
        // toast.error(
        //   `The difference between start time and end time should be less than or equal ${maxTeamMeetingLength} minutes for a Team Meeting.`,
        //   { position: toast.POSITION.TOP_RIGHT }
        // );
        setEndTimeAleart(
          `The difference between start time and end time should be less than or equal ${maxTeamMeetingLength} minutes for a Team Meeting.`
        );
        return;
      }
    } else {
      if (getDifferenceBetweenMinutes(startTime, newEndTime) > serviceLength) {
        setEndTime("");
        // toast.error(
        //   `The difference between start time and end time should be less than or equal ${serviceLength} minutes.`,
        //   { position: toast.POSITION.TOP_RIGHT }
        // );
        setEndTimeAleart(
          `The difference between start time and end time should be less than or equal ${serviceLength} minutes.`
        );
        return;
      }
    }
    // Set your endTime state here
    setEndTime(newEndTime);
  };

  return (
    <>
      <Header />
      <ToastContainer style={{ top: "7rem", position: "fixed" }} />
      <div className="container-fluid custom-container my-5">
        <div className="container-fluid my-5 h-100">
          <div
            className="container card my-4"
            style={{ background: "transparent" }}
          >
            <div className="row p-2 text-center">
              <h5>{name}</h5>
            </div>
            <hr className="m-0" />
            <div className="row my-4">
              <form
                onSubmit={isCancellation ? handleCancellation : handleSubmit}
              >
                <div className="mb-3 row">
                  <label className="col-sm-2 col-form-label">
                    Service Status
                  </label>
                  <div className="col-sm-10">
                    <select
                      className="form-select form-select-sm"
                      aria-label="Small select example"
                      name="session_status"
                      required
                      defaultValue=""
                      onChange={handleServiceStatusChange}
                      value={serviceStatus}
                    >
                      <option value="">Select Service Status</option>
                      <option value="1 - Ongoing IFSP Service">
                        Ongoing IFSP Service
                      </option>
                      <option value="5 - Compensatory Service Provided">
                        Compensatory Service Provided
                      </option>
                      <option value="6 - Team Meeting">Team Meeting</option>
                      <option value="4 - Make-up Service Provided">
                        Make-up Service Provided
                      </option>
                      <option value="2 - Practitioner Missed/Cancelled (inc weather related)">
                        Practitioner Missed/Cancelled (inc weather related)
                      </option>
                      <option value="3 - Family Missed/Cancelled (inc weather related)">
                        Family Missed/Cancelled (inc weather related)
                      </option>
                    </select>
                  </div>
                </div>

                {!isCancellation && (
                  <div className="mb-3 row">
                    <label className="col-sm-2 col-form-label">Location</label>
                    <div className="col-sm-10">
                      <select
                        className="form-select form-select-sm"
                        aria-label="Small select example"
                        name="session_location_codes"
                        required
                        defaultValue=""
                      >
                        <option value="">Select Location</option>
                        <option value="1 - Home">Home</option>
                        <option value="2 - Inclusive Community EC Program">
                          Inclusive Community EC Program
                        </option>
                        <option value="3 - Inclusive EIP EC Program">
                          Inclusive EIP EC Program
                        </option>
                        <option value="4 - EC Program-Children with Disabilities">
                          EC Program-Children with Disabilities
                        </option>
                        <option value="5 - Hospital (Inpatient)">
                          Hospital (Inpatient)
                        </option>
                        <option value="6 - Residential Facility">
                          Residential Facility
                        </option>
                        <option value="7 - Service Provider Clinic/Center/Office">
                          Service Provider Clinic/Center/Office
                        </option>
                        <option value="8 - Phone/Video Conferencing">
                          Phone/Video Conferencing
                        </option>
                      </select>
                    </div>
                  </div>
                )}

                {!isCancellation && (
                  <div className="mb-3 row">
                    <label className="col-sm-2 col-form-label">
                      Service Type
                    </label>
                    <div className="col-sm-10">
                      <select
                        className="form-select form-select-sm"
                        aria-label="Small select example"
                        name="session_type_code"
                        defaultValue=""
                        required
                        disabled={
                          serviceType === "OT - Occupational Therapy" ||
                          serviceType === "PT - Physical Therapy" ||
                          serviceType === "SLP - Speech/Language Therapy" ||
                          serviceType === "DI - Developmental Intervention" ||
                          serviceType === "SW - Social Worker" ||
                          serviceType === "I/T - Interpreter/Translator"
                        }
                        value={serviceType}
                        onChange={(e) => setServiceType(e.target.value)}
                      >
                        <option value="">Select Service Type</option>

                        {serviceStatus !== "6 - Team Meeting" ? (
                          <>
                            <option value="EV - Evaluation">
                              EV - Evaluation
                            </option>
                            <option value="AS - Assessment">
                              AS - Assessment
                            </option>
                            <option value="IFSP - Meeting">
                              IFSP - Meeting
                            </option>
                            <option value="AU - Audiology">
                              AU - Audiology
                            </option>
                            <option value="DI - Developmental Intervention">
                              DI - Developmental Intervention
                            </option>
                            <option value="FT - Family Training">
                              FT - Family Training
                            </option>
                            <option value="HS - Health Service">
                              HS - Health Service
                            </option>
                            <option value="MS - Medical Service">
                              MS - Medical Service
                            </option>
                            <option value="NU - Nursing">NU - Nursing</option>
                            <option value="NT - Nutrition">
                              NT - Nutrition
                            </option>
                            <option value="OT - Occupational Therapy">
                              OT - Occupational Therapy
                            </option>
                            <option value="PT - Physical Therapy">
                              PT - Physical Therapy
                            </option>
                            <option value="PSY - Psychological">
                              PSY - Psychological
                            </option>
                            <option value="SLP - Speech/Language Therapy">
                              SLP - Speech/Language Therapy
                            </option>
                            <option value="SW - Social Worker">
                              SW - Social Worker
                            </option>
                            <option value="VI - Vision">VI - Vision</option>
                            <option value="CC - Child Care/Respite">
                              CC - Child Care/Respite
                            </option>
                            <option value="I/T - Interpreter/Translator">
                              I/T - Interpreter/Translator
                            </option>
                            <option value="E/S - Escort/Security">
                              E/S - Escort/Security
                            </option>
                            <option value="TPC - Transition Planning Conference">
                              TPC - Transition Planning Conference
                            </option>
                          </>
                        ) : (
                          <>
                            <option value="IFSP - Meeting">
                              IFSP - Meeting
                            </option>
                            <option value="TPC - Transition Planning Conference">
                              TPC - Transition Planning Conference
                            </option>
                          </>
                        )}
                      </select>
                    </div>
                  </div>
                )}

                <div className="mb-3 row">
                  <label className="col-sm-2 col-form-label">
                    Service Date
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="date"
                      className="form-control"
                      id="serviceDate"
                      name="completed_date"
                      max={getTodayDate()}
                      min={getMinDate()}
                      value={serviceDateInput}
                      onChange={handleServiceDateChange}
                      required
                    />
                  </div>
                </div>

                {!isCancellation && (
                  <div className="mb-3 row" id="startTime">
                    <label className="col-sm-2 col-form-label">
                      Start Time
                    </label>
                    <div className="col-sm-10 ">
                      <div className="d-flex">
                        <input
                          type="time"
                          className="form-control"
                          id="serviceStartTime"
                          name="start_time"
                          value={startTime}
                          onChange={handleStartTimeChange}
                          twelvehour="true"
                          required
                        />

                        <a
                          className="mt-2 ms-2 text-dark"
                          onClick={handleStartClear}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </a>
                      </div>
                      {startTimeAleart && (
                        <div>
                          <p className="text-danger mt-1">{startTimeAleart}</p>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {!isCancellation && (
                  <div className="mb-3 row" id="endTime">
                    <label className="col-sm-2 col-form-label">End Time</label>
                    <div className="col-sm-10">
                      <div className=" d-flex">
                        <input
                          type="time"
                          className="form-control"
                          id="serviceEndTime"
                          name="end_time"
                          value={endTime}
                          onChange={handleEndTimeChange}
                          style={{
                            borderColor: endTimeAleart ? "red" : "",
                          }}
                          required
                        />
                        <a
                          className="mt-2 ms-2 text-dark"
                          onClick={handleClear}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </a>
                      </div>
                      {endTimeAleart && (
                        <div>
                          <p className="text-danger mt-1">{endTimeAleart}</p>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="mb-3 row">
                  <label className="col-sm-2 col-form-label">Note</label>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      className="form-control"
                      id="serviceDate"
                      name="note"
                      placeholder="Enter Note (Optional)"
                    />
                  </div>
                </div>

                {!isCancellation && (
                  <div className="mb-4 row" id="checkbox-eims">
                    <label
                      className="form-check-label col-sm-3 col-form-label"
                      for="flexSwitchCheckDefault"
                    >
                      Responsible Party Available
                    </label>
                    <div className="form-check form-switch col-sm-8 ms-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="responsible_party_available"
                        value={isCheckboxChecked}
                        id="flexCheckChecked"
                        checked={isCheckboxChecked}
                        onChange={(e) => {
                          setIsCheckboxChecked(e.target.checked);
                        }}
                      />
                    </div>
                  </div>
                )}

                <div ref={showRef} style={{ marginTop: "0rem" }}>
                  {isCheckboxChecked && !isCancellation && (
                    <div
                      className="mb-3 row"
                      id="signature-pad"
                      ref={divRef}
                      style={{
                        background: "rgb(248 248 248)",
                        padding: "10px 0",
                      }}
                    >
                      <div className="col-12 offset-0 signature-box">
                        <label className="col-sm-3 col-form-label">
                          Responsible Party Signature
                        </label>
                        <div style={{ overflow: "hidden" }}>
                          <a
                            className="position-absolute signature-erase"
                            onClick={clearSignature}
                          >
                            <i className="fa-solid fa-eraser"></i>
                          </a>
                          <SignatureCanvas
                            ref={signatureCanvasRef}
                            penColor="black"
                            canvasProps={{
                              width: 500,
                              height: 200,
                              className: "signatureCanvas",
                            }}
                            onEnd={saveSignature}
                          />

                          {signatureData && (
                            <div className="text-center mt-3">
                              <img
                                src={signatureData}
                                style={{
                                  width: "30%",
                                  border: "1px solid black",
                                  background: "white",
                                }}
                                alt="signature Image"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <hr />
                <div className="mb-3 row">
                  <div className="container">
                    <div
                      className="container-fluid d-flex flex-column justify-content-center align-items-center"
                      style={{ paddingBottom: "10px" }}
                    >
                      <p className="my-2 text-center ">
                        <strong>
                          I certify that above services were provided in
                          accordence with the child'd IFSP.
                        </strong>
                      </p>
                      {isloading ? (
                        <Button variant="dark" disabled>
                          Submitting...
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </Button>
                      ) : (
                        <input
                          type="submit"
                          // style={{ width: '30%' }}
                          className="btn btn-dark text-white"
                          value="Submit"
                        />
                      )}
                    </div>
                  </div>
                </div>

                <hr />
              </form>
            </div>
            <h5 className="text-center mb-3">
              {" "}
              &bull; Supporting Families Supporting Children
            </h5>
          </div>
        </div>
      </div>
    </>
  );
}

export default EIMSForm;
