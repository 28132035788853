import React, { useEffect, useState } from "react";
import axios from "axios";
import { Cookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APIEndpoints } from "./APIEndpoints";

const LoginForm = (props) => {
  const [loading, setLoading] = useState(false);
  const cookie = new Cookies();
  const nav = useNavigate();
  const [user, setUser] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    const data = cookie.get("logindata");
    if (data) {
      nav("/");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Start loading when the form is submitted

    axios
      .post(APIEndpoints.LOGIN, {
        username: user.username,
        password: user.password,
      })
      .then((res) => {
        cookie.set("logindata", JSON.stringify(res.data));
        nav("/");
      })
      .catch((error) => {
        console.log(error);
        const status_code = error.response.status;

        if (status_code >= 400 && status_code < 600) {
            console.log("Retrying...");
          axios
            .post(APIEndpoints.LOGIN, {
              username: user.username,
              password: user.password,
            })
            .then((res) => {
              cookie.set("logindata", JSON.stringify(res.data));
              nav("/");
            })
            .catch((error) => {
                console.error(`Request failed with status code ${status_code}`);
                toast.error("Incorrect Email or Password ", {
                  position: toast.POSITION.TOP_RIGHT,
                });
            });
        } else {
            console.error(`Request failed with status code ${status_code}`);
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT });
        }
      })
      .finally(() => {
        setLoading(false); // Stop loading after the request is complete
      });
  };

  return (
    <>
      <section className="LoginPage">
        <div className="d-flex justify-content-center text-center">
          {/* <div className='container text-center'> */}
          <div className="col-5 mt-2">
            <img
              src="/Therapy Associates_Logo.png"
              alt="logo"
              style={{ maxWidth: "180px" }}
            />
          </div>
          {/* </div> */}
        </div>
        <hr />
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-8 col-lg-6 col-xl-4 mt-4">
              <form onSubmit={handleSubmit}>
                {/* Email input  */}
                <div className="form-outline mb-4 text-start">
                  <label className="form-label" htmlFor="form3Example3">
                    Email address
                  </label>
                  <input
                    type="email"
                    id="form3Example3"
                    className="form-control form-control-lg"
                    placeholder="Enter a valid email address"
                    value={user.username}
                    onChange={(e) => {
                      setUser({ ...user, username: e.target.value });
                    }}
                    required
                  />
                </div>

                {/* Password input  */}
                <div className="form-outline mb-3 text-start">
                  <label className="form-label" htmlFor="form3Example4">
                    Password
                  </label>
                  <input
                    type="password"
                    id="form3Example4"
                    className="form-control form-control-lg"
                    placeholder="Enter password"
                    value={user.password}
                    onChange={(e) => {
                      setUser({ ...user, password: e.target.value });
                    }}
                    required
                  />
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <div className="form-check mb-0">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                      id="form2Example3"
                    />
                    <label className="form-check-label" htmlFor="form2Example3">
                      Remember me
                    </label>
                  </div>
                  <Link to="/reset-password" className="text-body">
                    Forgot password?
                  </Link>
                </div>

                <div className="text-center mt-4">
                  {/* Show the login button or the loader based on the loading state */}
                  {loading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <button type="submit" className="btn btn-primary btn-lg">
                      Login
                    </button>
                  )}
                  {/* <p className="small fw-bold mt-2 pt-1 mb-0">
                    Don't have an account?{" "}
                    <a href="#!" className="link-danger">
                      Register
                    </a>
                  </p> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer style={{ top: '7rem', position: 'fixed' }} />
    </>
  );
};

export default LoginForm;

