import React from "react";
import Header from "./Header";
import CustomPDFViewer from "./CustomPDFViewer";
import filePath from '../Assets/pdf/testing.pdf'

function DemoPDF() {

    return (
        <>
            <Header />
            <div className="my-5">
                <CustomPDFViewer pdfText="NJEIS Form" pdfPath={filePath}/>
            </div>
        </>
    );
}

export default DemoPDF;

