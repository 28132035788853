import React, { useState } from "react";
import Header from "./Header";
import { useLocation, useNavigate } from "react-router-dom";

function TrainingsVideo() {

    const location = useLocation();
    const navigate = useNavigate();

    const [type, setType] = useState(location?.state?.type)
    const [id, setId] = useState(location?.state?.id)


    const handelSubmit = () => {
        if (type === "Fraud, Waste, and Abuse"){
            navigate('/fwp-quiz',{
                state: {
                    type: type,
                    id: id
                },
            });
        }

        if (type === "Universal Precautions"){
            navigate('/universal-quiz',{
                state: {
                    type: type,
                    id: id
                },
            });
        }
    }
    
    return (
        <>
            <Header />
            <div className="container custom-container my-5">
                <h5 className="text-center">Please watch the training video before continuing</h5>
                <div className="row my-4">
                        <iframe
                            title="Training Video"
                            src={ type === 'Universal Precautions' ? 'https://player.vimeo.com/video/389601955' : 'https://player.vimeo.com/video/389601955'}
                            width="640"
                            height="360"
                            allow="autoplay; fullscreen"
                            allowFullScreen
                        ></iframe>
                </div>
                <div className="row my-4">
                    <div className="text-center">
                        <button className="btn btn-primary my-4 text-white" onClick={handelSubmit}>Continue</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TrainingsVideo;

