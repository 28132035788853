import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import { ToastContainer, toast } from "react-toastify";
import { APIEndpoints } from "./APIEndpoints";
import Cookies from "universal-cookie";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import PolicyPDF from "../Assets/pdf/UPPolicy.pdf";
import SignatureCanvas from "react-signature-canvas";

const UniversalQuiz = () => {
  const questions = [
    {
      id: 1,
      question:
        "Universal precautions are any functional barrier which prevents the spread of the infectious process, which include:",
      options: [
        "Disinfecting solutions such as bleach.",
        "Hand washing.",
        "Wearing gloves or a mask.",
        "All of the above.",
      ],
      correctAnswer: "All of the above.",
    },
    {
      id: 2,
      question:
        "When working with a child, if the child soils his/her diaper, you should:",
      options: [
        "Change the diaper yourself without using gloves.",
        "Change the diaper yourself using gloves.",
        "Stop servicing the child until caretaker changes the diaper.",
        "Continue servicing the child as long as it is not leaking.",
      ],
      correctAnswer:
        "Stop servicing the child until caretaker changes the diaper.",
    },
    {
      id: 3,
      question: "The term bodily fluids includes:",
      options: [
        "Urine and feces.",
        "Saliva.",
        "Blood or drainage from scrapes and cuts.",
        "All of the above.",
      ],
      correctAnswer: "All of the above.",
    },
    {
      id: 4,
      question:
        "Which of the following would NOT be considered a proper hand-washing technique?",
      options: [
        "Using soap, warm water and friction while lathering and scrubbing for 5 seconds.",
        "If hand washing facilities are not available, using a waterless hand washing product, such as Purell.",
        "Using soap, warm water and friction while lathering and scrubbing for 15-30 seconds.",
        "Washing hands thoroughly both before and after client contact.",
      ],
      correctAnswer:
        "Using soap, warm water and friction while lathering and scrubbing for 5 seconds.",
    },
    {
      id: 5,
      question: "When is it NOT necessary to wear gloves?",
      options: [
        "When direct contact with any bodily fluids is anticipated.",
        "When contact with non-intact skin is anticipated.",
        "When child appears to be healthy with no drainage of bodily fluids.",
      ],
      correctAnswer:
        "When child appears to be healthy with no drainage of bodily fluids.",
    },
    {
      id: 6,
      question:
        "It is better for a practitioner to bring his/her own toys/equipment instead of using those already found in the home environment.",
      options: ["True", "False"],
      correctAnswer: "False",
    },
    {
      id: 7,
      question:
        "Which of the following is NOT a clinically acceptable reason for a practitioner to introduce new toys to a child? ",
      options: [
        "In order to create excitement about participating in therapy.",
        "Because it is easier to bring your own rather than look around the home for useful toys.",
        "To teach a family member what toys and techniques are the most beneficial to bring about a desired result.",
        "To create motivation so that a child makes an effort to reach a desired result.",
      ],
      correctAnswer:
        "Because it is easier to bring your own rather than look around the home for useful toys.",
    },
    {
      id: 8,
      question:
        "If a toy is brought into a home to be used by a child, it must be sterilized:",
      options: [
        "Each time it is brought from the home.",
        "Once a day.",
        "Only if the child had bodily fluid drainage.",
        "If it looks dirty.",
      ],
      correctAnswer: "Each time it is brought from the home.",
    },
    {
      id: 9,
      question: "Which of the following are proper methods of sterilization?",
      options: [
        "Use of new Clorox wipes (or similar) over all surfaces of the toy.",
        "Use of a dishwasher.",
        "Submerging toys in a 1:10 bleach solution and rinse thoroughly under running water and air dry.",
        "All of the above.",
      ],
      correctAnswer: "All of the above.",
    },
    {
      id: 10,
      question:
        "In which of the following scenarios is it ok NOT to wash your hands (/use a hand sanitzer)?",
      options: [
        "If the home appears to be clean.",
        "If the child is healthy and is not sneezing or coughing.",
        "It is NEVER ok not to wash your hands.",
        "If you washed your hands when you left your last session.",
      ],
      correctAnswer: "It is NEVER ok not to wash your hands.",
    },
    // Add more questions as needed
  ];

  const [policyClicked, setPolicyClicked] = useState(false);

  const handlePolicyClick = () => {
    setPolicyClicked(true);
  };

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [newAnswers, setNewAnswers] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [score, setScore] = useState(0);
  const [trainingLog, setTrainingLog] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const [fieldId, setFieldId] = useState(location?.state?.id);

  const [isTermChecked, setTermChecked] = useState(true);

  const handleCheckboxChange = () => {
    setTermChecked(!isTermChecked);
  };

  const [showQuestions, setShowQuestions] = useState(false);

  // LOGIC FOR SIGNATURE PAD

  const [file, setFile] = useState();

  const signatureCanvasRef = useRef(null);

  const base64ToFile = (base64, filename) => {
    const arr = base64.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const clearSignature = () => {
    signatureCanvasRef.current.clear();
    setFile(null);
  };

  const saveSignature = () => {
    const signatureImage = signatureCanvasRef.current.toDataURL();
    const file = base64ToFile(signatureImage, "signature.png");
    setFile(file);
  };

  //  End Signature Logic

  async function handleSubmitSignature() {
    if (!policyClicked) {
      toast.error("Please view terms and conditions to process ahead", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    // Check if the checkbox is checked
    if (!isTermChecked) {
      toast.error("Agree to all of the terms of the policy !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!file) {
      toast.error("Please upload signature", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setShowQuestions(true);
  }

  const handleSignUpload = async () => {
    try {
      const cookie = new Cookies();
      const token = cookie.get("logindata");
      const formData = new FormData();
      formData.append("linked_id", fieldId);
      formData.append("signature", file);
      formData.append("type", "Universal");

      // Make the POST request to the API using Axios
      const response = await axios.post(
        APIEndpoints.SAVE_POLICY_SIGNATURE,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response from the API
      const data = response.data;
    } catch (error) {
      toast.error("Error in Sign Upload", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleAnswerSubmit = () => {
    if (selectedOption !== null && selectedOption !== undefined) {
      // Check Answer is correct
      const isCorrect =
        selectedOption === questions[currentQuestion].correctAnswer;
      let filterans = userAnswers.filter(
        (it) => it.questionId !== questions[currentQuestion].id
      );

      setUserAnswers([
        ...filterans,
        {
          questionId: questions[currentQuestion].id,
          selectedAnswer: selectedOption,
        },
      ]);

      setTrainingLog((prevTrainingLog) => [
        ...prevTrainingLog,
        {
          [questions[currentQuestion].id]:
            questions[currentQuestion].options.indexOf(selectedOption) + 1,
        },
      ]);

      // Increase the score if the answer is correct
      if (isCorrect) {
        setScore(score + 1);
      }

      // set selected option to null now
      setSelectedOption(null);

      // Move to the next question
      if (currentQuestion < questions.length - 1) {
        setCurrentQuestion(currentQuestion + 1);
      } else {
        setCurrentQuestion(currentQuestion + 1);
      }
      try {
        setquestion_id(questions[currentQuestion + 1].id);
      } catch (error) {
        console.log("");
      }
    } else {
      toast.error("Select an option", { position: toast.POSITION.TOP_RIGHT });
    }
  };
  const [question_id, setquestion_id] = useState("");
  const handelResetButton = () => {
    setCurrentQuestion(currentQuestion - 1);
    setquestion_id(questions[currentQuestion - 1].id);
    setSelectedOption(null);
  };

  const handelScoreSubmit = async () => {
    const cookie = new Cookies();
    const token = cookie.get("logindata");

    const { access_token, sf_id } = token;

    if (score <= 7) {
      toast.error(
        "Unfortunately you did not pass the training. Please retake the training as soon as it is available in the tasks.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }

    axios
      .post(`${APIEndpoints.THERAPIST_TRAINING_SUBMIT}${sf_id}/`, {
        score: score,
        type: "Universal Precautions",
        trainingLog: JSON.stringify(trainingLog),
        fieldId: fieldId,
      })
      .then((res) => {
        console.log("Score submitted");
      })
      .catch((err) => {
        console.error(err);
        toast.error("Failed to submit score. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleButtonClick = async () => {
    setIsLoading(true);
    if (!file) {
      toast.error("Please add signature first !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
      return;
    }
    await handelScoreSubmit();
    await handleSignUpload();
    toast.success("Successfully submitted!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setIsLoading(false);
    setTimeout(() => {
      navigate("/");
    }, 200);
  };

  useEffect(() => {
    if (question_id) {
      let i;
      userAnswers.map((it, index) => {
        if (it.questionId == question_id) {
          i = index;
        }
      });
      setSelectedOption(userAnswers[i]?.selectedAnswer);
    }
  }, [question_id, currentQuestion]);

  const renderOptions = () => {
    const optionsLetters = ["A", "B", "C", "D"];

    return questions[currentQuestion].options.map((option, index) => (
      <li key={index} className="d-flex text-start my-2">
        <input
          type="radio"
          id={`option-${index}`}
          value={option}
          checked={selectedOption === option}
          onChange={() => handleOptionChange(option)}
          className="input-quiz"
        />
        <label
          htmlFor={`option-${index}`}
          className="ms-4"
        >{`${optionsLetters[index]}. ${option}`}</label>
      </li>
    ));
  };

  return (
    <>
      <Header />
      <div className="quiz-container">
        {showQuestions ? (
          <>
            {currentQuestion < questions.length ? (
              <>
                <div className="quizContainer">
                  <h2 className="question-heading">
                    Q{questions[currentQuestion]?.id}.{" "}
                    {questions[currentQuestion]?.question}
                  </h2>
                  <ul className="optionsList">{renderOptions()}</ul>
                  <div className="d-flex justify-content-between">
                    <button
                      onClick={handelResetButton}
                      className="btn btn-primary my-4"
                      disabled={currentQuestion === 0}
                    >
                      Prev
                    </button>
                    <button
                      onClick={handleAnswerSubmit}
                      className="btn btn-primary my-4"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="quizSignContainer">
                  <p>Survey Completed</p>
                  <div className="text-center my-4">
                    {isLoading ? (
                      <Button variant="dark" disabled>
                        Submitting
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </Button>
                    ) : (
                      <input
                        type="submit"
                        onClick={handleButtonClick}
                        className="btn btn-dark text-white"
                        value="Submit"
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="quizSignContainer">
              <h4>Universal Precautions</h4>
              <p>
                Here is the
                <a
                  href={PolicyPDF}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  onClick={handlePolicyClick}
                >
                  <b className="fs-5 text-bold"> Policy</b>
                </a>
                . Please read before taking the quiz.
              </p>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input d-none"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="yes"
                  checked={isTermChecked}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox1">
                  I agree to all of the terms of the policy
                </label>
              </div>

              <div className="row my-4 signature-quiz-pad">
                <div
                  className="mb-3"
                  style={{
                    background: "rgb(248, 248, 248)",
                    padding: "20px",
                    textAlign: "center",
                  }}
                >
                  {policyClicked ? (<div
                    style={{
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <a
                      className="position-absolute signature-erase"
                      onClick={clearSignature}
                    >
                      <i className="fa-solid fa-eraser"></i>
                    </a>
                    <SignatureCanvas
                      ref={signatureCanvasRef}
                      penColor="black"
                      canvasProps={{
                        width: 500,
                        height: 200,
                        className: "signatureCanvas",
                      }}
                      onEnd={saveSignature}
                    />
                  </div>) : (<div
                    style={{
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <p>Please view terms and conditions first</p>
                  </div>)}
                </div>
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-secondary ms-2 px-4"
                    onClick={handleSubmitSignature}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <ToastContainer style={{ top: "7rem", position: "fixed" }} />
    </>
  );
};

export default UniversalQuiz;
