import React, { useState, useEffect } from "react";
import SinglePagePDFViewer from "./pdf/single-page";
import "../styles.css";

function CustomPDFViewer(props) {


  return (
    <>
      <div className="container custom-container my-5">
        <h3 className="text-center">{props.pdfText}</h3>
        <SinglePagePDFViewer pdf={props.pdfPath} />
      </div>
    </>
  );
}

export default CustomPDFViewer;

