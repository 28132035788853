import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import { ToastContainer, toast } from "react-toastify";
import { APIEndpoints } from "./APIEndpoints";
import Cookies from "universal-cookie";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import PolicyPDF from "../Assets/pdf/FWAPolicy.pdf";
import SignatureCanvas from "react-signature-canvas";

const FwpQuiz = () => {
  const questions = [
    {
      id: 1,
      question:
        "If you suspect that fraud, waste or abuse is being perpetrated by a member of your team you should:",
      options: [
        "Not tell anyone because you don't want to get anyone in trouble.",
        "Write it in your clinical notes.",
        "Call the Therapy Associates administrator to report the suspected act.",
        "Call the REIC to report the suspected act.",
      ],
      correctAnswer:
        "Call the Therapy Associates administrator to report the suspected act.",
    },
    {
      id: 2,
      question:
        "Depending on the nature of the allegation, Therapy Associates could do which of the following when conducting an internal investigation of fraud, waste, or abuse?",
      options: [
        "Question the person making the allegation.",
        "Speak with the alleged perpetrator.",
        "Examine the accused practitioner's logged sessions on the EIMS and the TA website.",
        "All of the above.",
      ],
      correctAnswer: "All of the above.",
    },
    {
      id: 3,
      question:
        "In order for a practitioner to receive payment for services provided to a child, the practitioner must:",
      options: [
        "Have a parent/caregiver signature for each session to be billed.",
        "Have logged all sessions on the EIMS and have a corresponding progress note for each of the dates billed.",
        "Have logged all sessions on the TA website and have confirmed their monthly billing on the TA website.",
        "All of the above.",
      ],
      correctAnswer: "All of the above.",
    },
    {
      id: 4,
      question:
        "If you entered the wrong dates or times by accident when logging a session on the EIMS you should:",
      options: [
        "Simply enter a new session with the changes on the EIMS.",
        "Request removal of the old session and enter a new session with the corrected times.",
        "Leave the session that you entered as is since the family doesnâ€™t have a cost share.",
      ],
      correctAnswer:
        "Request removal of the old session and enter a new session with the corrected times.",
    },
    {
      id: 5,
      question:
        "If you need to make a change to a session logged on the TA website you should:",
      options: [
        "Keep the old session on the TA site for reference and upload an additional session by using the submit previous claim function.",
        "First ask TA to delete your incorrect session. Have Mom sign a verification log form with corrected times or type of session. Then upload it to the TA website by going to submit previous claim. Fill out the information and attach a picture of the verification log.",
        "Have parent sign a new SEVF with the corrected information and keep that paper in your files.",
      ],
      correctAnswer:
        "First ask TA to delete your incorrect session. Have Mom sign a verification log form with corrected times or type of session. Then upload it to the TA website by going to submit previous claim. Fill out the information and attach a picture of the verification log.",
    },
    {
      id: 6,
      question: "An example of Abuse is:",
      options: [
        "Requesting removal of an inaccurate claim from the EIMS",
        "Recommending an increase in services when the child needs more intervention",
        "Recommending an assessment in another discipline without clinical justification.",
        "Billing for a session that was not provided by signing for the parent, done because you were scared since you missed so many sessions",
      ],
      correctAnswer:
        "Recommending an assessment in another discipline without clinical justification.",
    },
    {
      id: 7,
      question:
        "Proof of the commission of fraud after a thorough investigation may result in the suspension of services to a child whose parents committed fraud, or disqualification of a practitioner who has been found to have committed fraud.",
      options: ["True", "False"],
      correctAnswer: "True",
    },
    {
      id: 8,
      question:
        "While doing ongoing assessment of a child a practitioner sees that the child is no longer eligible for EI services. However, the parents are insistent that services not be decreased. What should the practitioner do?",
      options: [
        "Not mention anything until the next meeting because the service page is valid until the end date anyway.",
        "Discharge the child because he is no longer eligible for services.",
        "Discuss the situation with the parents and write a form 25 requesting a decrease in services to make sure that the child has a proper transition.",
        "None of the above",
      ],
      correctAnswer:
        "Discuss the situation with the parents and write a form 25 requesting a decrease in services to make sure that the child has a proper transition.",
    },
    {
      id: 9,
      question:
        "A therapist is on a case with a translator for a 1 time a week case. On the first visit, they realize that the parent/caregiver really does speak and read enough English,but the family wants the child to have services in their native language. What should the practitioner and translator do?",
      options: [
        "Nothing, since it would be nice for the family to have this option.",
        "Alert Therapy Associates so we can discuss discontinuing translation with the Service coordinator.",
        ".Have the translator only go in for the last half hour of each session to translate the note and speak to parent/caregiver.",
        "Have the translator only go in once a month.",
      ],
      correctAnswer:
        "Alert Therapy Associates so we can discuss discontinuing translation with the Service coordinator.",
    },
    {
      id: 10,
      question:
        "Evidence of fraud may be referred by the New Jersey Early Intervention system to the New Jersey Division of Criminal Justice for criminal prosecution.",
      options: ["True", "False"],
      correctAnswer: "True",
    },
    {
      id: 11,
      question:
        "You arrive at a home and no one answers the door. What should you do:",
      options: [
        "Enter this as a direct child service on the EIMS since anyways the family does not have a cost share.",
        "Log this as a practitioner cancellation so you can do a makeup.",
        "Log the date as a family cancelation on the EIMS and the TA website.",
      ],
      correctAnswer:
        "Log the date as a family cancelation on the EIMS and the TA website.",
    },
    {
      id: 12,
      question:
        "You are unable to log your session on the EIMS since it was not updated since the review. You should:",
      options: [
        "Log your session on the TA website and choose unable to enter on EIMS. Then inform Therapy Associates so they can reach out to the service coordinator to  finalize the IFSP on the EIMS.",
        "Have the parent/caregiver sign a verification log.",
        "Cancel the session and reschedule for when the IFSP is updated.",
      ],
      correctAnswer:
        "Log your session on the TA website and choose unable to enter on EIMS. Then inform Therapy Associates so they can reach out to the service coordinator to  finalize the IFSP on the EIMS.",
    },
    {
      id: 13,
      question:
        "According to State policy, if a parent claims that a therapist has billed for 60 minutes but only provided 45 minutes of service TA must:",
      options: [
        "Report the allegation to the NJEIS within two business days.",
        "Conduct an investigation.",
        "Report to the NJEIS if the allegation is credible.",
        "All of the above",
      ],
      correctAnswer: "All of the above",
    },
    {
      id: 14,
      question:
        "Which of the following is a way in which Therapy Associates combats fraud, waste and abuse?",
      options: [
        "TA conducts background checks of all practitioners.",
        "TA calls parents to ask whether services are being provided as per the IFSP.",
        "TA calls parents to ask whether therapists come regularly and on time.",
        "All of the above",
      ],
      correctAnswer: "All of the above",
    },
    {
      id: 15,
      question:
        "When billing for an IFSP meeting, for how much time may a therapist bill?",
      options: [
        "It is up to the service coordinator. You may bill for the amount of time the SC fills in the team page next to your name.",
        "You may always bill for an hour. IFSP meetings generally take that long.",
        "You must make sure that the team page has the actual time you were there filled in correctly. You may only bill for the time you were actually there.",
        "You may never bill for IFSP sessions, they are a family entitlement and cannot be billed.",
      ],
      correctAnswer:
        "You must make sure that the team page has the actual time you were there filled in correctly. You may only bill for the time you were actually there.",
    },
    {
      id: 16,
      question:
        "A practitioner enters a â€œdirect service sessionâ€ with a note on the EIMS, and enters a â€œdirect service sessionâ€ with a guardianâ€™s signature on the TA website for a session that was cancelled. This is:",
      options: ["Correctly logged", "Fraud", "Waste", "Abuse"],
      correctAnswer: "Fraud",
    },
    // Add more questions as needed
  ];

  const [policyClicked, setPolicyClicked] = useState(false);

  const handlePolicyClick = () => {
    setPolicyClicked(true);
  };
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [score, setScore] = useState(0);
  const [trainingLog, setTrainingLog] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const [fieldId, setFieldId] = useState(location?.state?.id);

  const [isTermChecked, setTermChecked] = useState(true);

  const handleCheckboxChange = () => {
    setTermChecked(!isTermChecked);
  };

  const [showQuestions, setShowQuestions] = useState(false);

  // LOGIC FOR SIGNATURE PAD

  const [file, setFile] = useState();

  const signatureCanvasRef = useRef(null);

  const base64ToFile = (base64, filename) => {
    const arr = base64.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const clearSignature = () => {
    signatureCanvasRef.current.clear();
    setFile(null);
  };

  const saveSignature = () => {
    const signatureImage = signatureCanvasRef.current.toDataURL();
    const file = base64ToFile(signatureImage, "signature.png");
    setFile(file);
  };

  //  End Signature Logic

  async function handleSubmitSignature() {
    if (!policyClicked) {
      toast.error("Please view terms and conditions to process ahead", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    // Check if the checkbox is checked
    if (!isTermChecked) {
      toast.error("Agree to all of the terms of the policy !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!file) {
      toast.error("Please upload signature", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setShowQuestions(true);
    localStorage.removeItem("policyClick");
  }

  const [question_id, setquestion_id] = useState("");
  const handleSignUpload = async () => {
    try {
      const cookie = new Cookies();
      const token = cookie.get("logindata");
      const { access_token, sf_id } = token;
      const formData = new FormData();
      formData.append("linked_id", fieldId);
      formData.append("signature", file);
      formData.append("type", "FWA");

      // Make the POST request to the API using Axios
      const response = await axios.post(
        APIEndpoints.SAVE_POLICY_SIGNATURE,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response from the API
      const data = response.data;
    } catch (error) {
      toast.error("Error in Sign Upload", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleAnswerSubmit = () => {
    if (selectedOption !== null && selectedOption !== undefined) {
      // Check Answer is correct
      const isCorrect =
        selectedOption === questions[currentQuestion].correctAnswer;

      setUserAnswers((prevAnswers) => [
        ...prevAnswers,
        {
          questionId: questions[currentQuestion].id,
          selectedAnswer: selectedOption,
        },
      ]);

      setTrainingLog((prevTrainingLog) => [
        ...prevTrainingLog,
        {
          [questions[currentQuestion].id]:
            questions[currentQuestion].options.indexOf(selectedOption) + 1,
        },
      ]);

      // Increase the score if the answer is correct
      if (isCorrect) {
        setScore(score + 1);
      }

      // set selected option to null now
      setSelectedOption(null);

      // Move to the next question
      if (currentQuestion < questions.length - 1) {
        setCurrentQuestion(currentQuestion + 1);
      } else {
        setCurrentQuestion(currentQuestion + 1);
      }
      try {
        setquestion_id(questions[currentQuestion + 1].id);
      } catch (error) {
        console.log("");
      }
    } else {
      toast.error("Select an option", { position: toast.POSITION.TOP_RIGHT });
    }
  };

  const handelResetButton = () => {
    setCurrentQuestion(currentQuestion - 1);
    setquestion_id(questions[currentQuestion - 1].id);
    setSelectedOption(null);
  };

  const handelScoreSubmit = async () => {
    const cookie = new Cookies();
    const token = cookie.get("logindata");

    const { access_token, sf_id } = token;

    if (score <= 11) {
      toast.error(
        "Unfortunately you did not pass the training. Please retake the training as soon as it is available in the tasks.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }

    axios
      .post(`${APIEndpoints.THERAPIST_TRAINING_SUBMIT}${sf_id}/`, {
        score: score,
        type: "Fraud, Waste, and Abuse",
        trainingLog: JSON.stringify(trainingLog),
        fieldId: fieldId,
      })
      .then((res) => {
        console.log("Score submitted");
      })
      .catch((err) => {
        console.error(err);
        toast.error("Failed to submit score. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleButtonClick = async () => {
    setIsLoading(true);
    if (!file) {
      toast.error("Please add signature first !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
      return;
    }
    await handelScoreSubmit();
    await handleSignUpload();
    toast.success("Successfully submitted!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setIsLoading(false);
    setTimeout(() => {
      navigate("/");
    }, 200);
  };

  useEffect(() => {
    if (question_id) {
      let i;
      userAnswers.map((it, index) => {
        if (it.questionId == question_id) {
          i = index;
        }
      });
      setSelectedOption(userAnswers[i]?.selectedAnswer);
    }
  }, [question_id, currentQuestion]);

  const renderOptions = () => {
    const optionsLetters = ["A", "B", "C", "D"];

    return questions[currentQuestion].options.map((option, index) => (
      <li key={index} className="d-flex text-start my-2">
        <input
          type="radio"
          id={`option-${index}`}
          value={option}
          checked={selectedOption === option}
          onChange={() => handleOptionChange(option)}
          className="input-quiz"
        />
        <label
          htmlFor={`option-${index}`}
          className="ms-4"
        >{`${optionsLetters[index]}. ${option}`}</label>
      </li>
    ));
  };

  return (
    <>
      <Header />
      <div className="quiz-container">
        {showQuestions ? (
          <>
            {currentQuestion < questions.length ? (
              <div className="quizContainer">
                <h2 className="question-heading">
                  Q{questions[currentQuestion].id}.{" "}
                  {questions[currentQuestion].question}
                </h2>
                <ul className="optionsList">{renderOptions()}</ul>
                <div className="d-flex justify-content-between">
                  <button
                    onClick={handelResetButton}
                    className="btn btn-primary my-4"
                    disabled={currentQuestion === 0}
                  >
                    Prev
                  </button>
                  <button
                    onClick={handleAnswerSubmit}
                    className="btn btn-primary my-4"
                  >
                    Next
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="quizSignContainer">
                  <p>Survey Completed</p>
                  <div className="text-center my-4">
                    {isLoading ? (
                      <Button variant="dark" disabled>
                        Submitting
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </Button>
                    ) : (
                      <input
                        type="submit"
                        onClick={handleButtonClick}
                        className="btn btn-dark text-white"
                        value="Submit"
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="quizSignContainer">
              <h4>Fraud, Waste, and Abuse</h4>
              <p>
                {" "}
                Here is the
                <a
                  href={PolicyPDF}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  onClick={handlePolicyClick}
                >
                  <b className="fs-5 text-bold"> Policy</b>
                </a>
                . Please read before taking the quiz.
              </p>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input d-none"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="yes"
                  checked={isTermChecked}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox1">
                  I agree to all of the terms of the policy
                </label>
              </div>
              <div className="row my-4 signature-quiz-pad">
                <div
                  className="mb-3"
                  style={{
                    background: "rgb(248, 248, 248)",
                    padding: "20px",
                    textAlign: "center",
                  }}
                >
                  {policyClicked ? (
                    <div
                      style={{
                        overflow: "hidden",
                        position: "relative",
                      }}
                    >
                      <a
                        className="position-absolute signature-erase "
                        onClick={clearSignature}
                      >
                        <i className="fa-solid fa-eraser"></i>
                      </a>
                      <SignatureCanvas
                        ref={signatureCanvasRef}
                        penColor="black"
                        canvasProps={{
                          width: 500,
                          height: 200,
                          className: "signatureCanvas",
                        }}
                        onEnd={saveSignature}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        overflow: "hidden",
                        position: "relative",
                      }}
                    >
                      <p>Please view terms and conditions first</p>
                    </div>
                  )}
                </div>
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-secondary ms-2 px-4"
                    onClick={handleSubmitSignature}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <ToastContainer style={{ top: "7rem", position: "fixed" }} />
    </>
  );
};

export default FwpQuiz;
