import React, { useState } from 'react';
import Header from './Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APIEndpoints } from './APIEndpoints';
import { useNavigate } from 'react-router-dom';


function FilePasswordFrom() {

    const [formPassword, setFormPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const handleSubmit = async (event) => {
        if (formPassword === ''){
            toast.error("Enter your password", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return
        }
        setIsLoading(true);
        const url = APIEndpoints.AUTHENTICATE_APP_ADMIN;
        const method = "POST";
        const requestBody = JSON.stringify({ "password": formPassword });
        const response = await fetch(url, {
            method,
            headers: {
                "Content-Type": "application/json",
            },
            body: requestBody,
        });

        if (response.ok) {
            setIsLoading(false);
            const responseData = await response.json();
            const status = responseData.status;
            if (status) {
                navigate('/file/upload/', {
                    state: {verified: true},
                });
            }
            else {
                toast.error("Not Authenticated.", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setFormPassword('');
            }

        } else {
            setIsLoading(false);
            toast.error("Not Authenticated", {
                position: toast.POSITION.TOP_RIGHT,
            });
            setFormPassword('');
        }
    };
    return (
        <>
            <Header />
            <ToastContainer style={{ top: '7rem', position: 'fixed' }} />

            <div className="container custom-container my-5">
                <div className="row justify-content-center my-4">
                    <div className="col-md-6">
                        <div className='box p-4'>
                            <div className="mb-3">
                                <label htmlFor="form3Example4" className="form-label">Enter Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="form3Example4"
                                    placeholder="Enter password"
                                    required
                                    value={formPassword}
                                    onChange={(e)=>{setFormPassword(e.target.value)}}
                                />
                            </div>
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-info text-white"
                                    onClick={handleSubmit}
                                >{isLoading ? ( <span>
                                    Submit.. {' '}
                                    <div className="spinner-border spinner-border-sm" role="status"></div>
                                  </span>) : ("Submit")}

                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default FilePasswordFrom;

