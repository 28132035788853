import React, { useEffect, useState } from "react";
import Header from "./Header";
import DownloadFile from "../Assets/icons/file-download.png";
import FormFile from "../Assets/pdf/NJEIS Form 25 Word.docx";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { APIEndpoints } from "./APIEndpoints";
import {
  setLocalStorageWithExpiration,
  getLocalStorageWithExpiration,
  getTimeElapsedFromLocalStorage,
} from "./localStorageUtil";
import LastUpdatedTime from "./LastUpdatedTime";

function ChildForm() {
  const [isloading, setIsLoading] = useState(false);

  // const [downloading, setDownloading] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  const [name, setname] = useState(location?.state?.name);
  const [description, setDescription] = useState(location?.state?.description);

  useEffect(() => {
    if (!description || !name) {
      navigate("/");
      return
    }
  }, [description, name, navigate]);

  const [data, setData] = useState({});
  // const [pendingForm25, setPendingForm25] = useState([]);

  const [lastUpdatedTime, setLastUpdatedTime] = useState(0);

  useEffect(() => {
    const value = getTimeElapsedFromLocalStorage("form25Details");
    setLastUpdatedTime(value);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (getLocalStorageWithExpiration("form25Details")) {
        setIsLoading(true);
        const cookieData = getLocalStorageWithExpiration("form25Details");
        setData(cookieData);
        setIsLoading(false);
      } else {
        try {

          setIsLoading(true);
          const response = await axios.get(
            `${APIEndpoints.FORM_25_DETAILS}${description.child_service_id}`
          );
          setData(response.data);
          // Set data to local Storage
          setLocalStorageWithExpiration("form25Details", response.data, 30);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [description]);

  // const fetchPendingData = async (id) => {
  //   try {
  //     setIsLoading(true);
  //     const response = await axios.get(
  //       `${APIEndpoints.FETCH_SAVE_FORM_25_DRAFT}${description.child_service_id}/`
  //     );
  //     setPendingForm25(response.data.data);
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchPendingData();
  // }, [description.child_service_id]);

  // const discard_saved_form = async (id) => {
  //   try {
  //     setIsLoading(true);
  //     const response = await axios.get(
  //       `${APIEndpoints.UPDATE_FORM_25_DRAFT}${id}/`
  //     );
  //     fetchPendingData();
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setIsLoading(false);
  //   }
  // };

  // async function downloadPdf(pdf_id) {
  //   try {
  //     setDownloading(true);
  //     const response = await axios.get(
  //       `${APIEndpoints.DOWNLOAD_FORM25_PDF}${pdf_id}`
  //     );
  //     if (response.data.file !== "") {
  //       downloadBase64File(response.data.file, "form-25");
  //     } else {
  //       console.error("Invalid file data");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setDownloading(false);
  //   }
  // }

  // function downloadBase64File(base64Data, fileName) {
  //   try {
  //     const byteCharacters = atob(base64Data);
  //     const byteArrays = [];
  //     for (let offset = 0; offset < byteCharacters.length; offset += 512) {
  //       const slice = byteCharacters.slice(offset, offset + 512);
  //       const byteNumbers = new Array(slice.length);
  //       for (let i = 0; i < slice.length; i++) {
  //         byteNumbers[i] = slice.charCodeAt(i);
  //       }
  //       const byteArray = new Uint8Array(byteNumbers);
  //       byteArrays.push(byteArray);
  //     }
  //     const blob = new Blob(byteArrays, { type: "application/pdf" });
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = fileName;
  //     a.click();
  //   } catch (error) {
  //     console.error("Error creating object URL:", error);
  //   }
  // }

  return (
    <>
      <Header />
      {isloading ? (
        <div className="detail-page container-fluid">
          <div className="container my-4">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="container custom-container my-5">
            <div className="p-3">
              <h5>{name}</h5>
              <p className="mb-0">Service Type: {description?.service_name}</p>
              <p className="mb-0">
                Service start Date: {description?.service_start_date}
              </p>
              <p className="mb-0">Interval: {description?.service_time}</p>
            </div>
            <div className="p-3">
              <p className="fw-semibold">
                Your next form 25 for {name} is Due on{" "}
                {data.due_date ? data.due_date.split("T")[0] : ""}. You can
                access the Form 25 document below and upload it to Therapy
                Associates for review below.{" "}
              </p>
            </div>

            <div className="box text-center p-3 mb-3 my-2 rounded d-flex flex-column justify-content-between">
              <p className="ms-2">
                Please complete a Form 25 for this child and email a copy to
                <a
                  className="ms-2"
                  style={{
                    color: "blue",
                    textDecoration: "none",
                  }}
                  href="mailto:administrator@therapyassociates.com"
                >
                  administrator@therapyassociates.com
                </a>
              </p>
              <p>
                Please click here to
                <a
                  className="mt-2 mx-2 text-decoration-none text-primary"
                  href={FormFile}
                  download
                >
                  download
                </a>
                the Form 25
              </p>
            </div>

            {/*
            {pendingForm25.length > 0 && (
              <div className="row justify-content-between mb-2">
                <div className="col">
                  <h>
                    <strong>Pending form 25s submitted</strong>
                  </h>
                </div>
                <div className="col"></div>
              </div>
            )}

            {pendingForm25.length > 0 &&
              pendingForm25.map((item) => (
                <div key={item.id} className="row justify-content-between">
                  <div className="col">
                    <p>
                      {item.updated_at.split("T")[0]}{" "}
                      {item.updated_at.split("T")[1].split(".")[0]}
                    </p>
                  </div>
                  <div className="col text-end">
                    <div className="col text-end">
                      <i
                        className="fa fa-pencil"
                        aria-hidden="true"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/pending-form-25", {
                            state: {
                              name: description.child_name,
                              service_id: description.child_service_id,
                              therapist_service_id:
                                description.therapist_service_id,
                              data: { item },
                            },
                          });
                        }}
                        style={{ cursor: "pointer" }}
                      ></i>
                      <i
                        className="fa-solid fa-trash-can ms-3"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          discard_saved_form(item.id);
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              ))}
            /*}

            {/*<div
              className="box text-center p-3 mb-3 my-2 rounded"
              onClick={(e) => {
                e.preventDefault();
                navigate("/add-form-25", {
                  state: {
                    name: description.child_name,
                    service_id: description.child_service_id,
                    therapist_service_id: description.therapist_service_id,
                  },
                });
              }}
            >
              <p className="ms-2">
                <i className="fa-regular fa-file-lines mx-2"></i>Click here to
                add a form 25
              </p>
            </div>*/}

            {/*
            <hr />

            <div className="row justify-content-between">
              <div className="col">
                <p>
                  <strong>Past form 25s submitted</strong>
                </p>
              </div>
              <div className="col"></div>
            </div>

            {data.form_25_data &&
              data.form_25_data.map((item) => (
                <div key={item.id} className="row justify-content-between">
                  <div className="col">
                    <p>
                      {item.created_at.split("T")[0]}{" "}
                      {item.created_at.split("T")[1].split(".")[0]}
                    </p>
                  </div>
                  <div className="col text-end">
                    <div className="col text-end">
                      <img
                        src={DownloadFile}
                        alt="file-logo"
                        onClick={(e) => {
                          e.preventDefault();
                          downloadPdf(item.content_document_id);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            */}
          </div>
        </>
      )}
      {lastUpdatedTime ? (
        <LastUpdatedTime time={lastUpdatedTime} />
      ) : (
        <LastUpdatedTime time={"0 min ago"} />
      )}
    </>
  );
}

export default ChildForm;
