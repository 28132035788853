import React, { useEffect, useState } from "react";
import Header from "./Header";
import { APIEndpoints } from "./APIEndpoints";
import axios from "axios";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";

function UnpaidSessions() {
  const [isLoading, setIsLoading] = useState(false);
  const [readySessions, setReadySessions] = useState({});
  const [notReadySessons, setNotReadySessions] = useState({});

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const cookie = new Cookies();
      const token = cookie.get("logindata");
      const { access_token, sf_id } = token;
      const response = await axios.get(
        `${APIEndpoints.UnpaidSessions}${sf_id}`
      );
      if (response.status === 200) {
        console.log(response);
        setReadySessions(response?.data?.ready_sessions);
        setNotReadySessions(response?.data?.not_ready_sessions);
        setIsLoading(false);
      } else {
        toast.success("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const [isOpenNext, setIsOpenNext] = useState(false);

  const handleToggleNext = () => {
    setIsOpenNext(!isOpenNext);
  };

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const [openIndexNext, setOpenIndexNext] = useState(null);

  const toggleAccordionNext = (index) => {
    setOpenIndexNext(openIndexNext === index ? null : index);
  };

  return (
    <>
      <ToastContainer />
      <Header />
      {isLoading ? (
        <div className="">
          <div className="card-body text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid custom-container my-5">
          <div className="container-fluid my-5 h-100">
            {readySessions.length === 0 && notReadySessons.length === 0 && (
              <div className="text-center">
                <p>No Unpaid Sessions</p>
              </div>
            )}

            {readySessions && readySessions.length > 0 && (
              <div
                className="d-flex justify-content-between mb-4 p-4"
                style={{
                  boxShadow:
                    "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
                  borderRadius: "5px",
                  background: "#0A1E4F",
                  color: "white",
                }}
                aria-hidden="true"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                onClick={handleToggle}
              >
                <div>
                  <h5 className="mb-0 font-weight-bold px-2 mb-3">
                    <strong>
                      {readySessions?.length &&
                        readySessions
                          .reduce((acc, item) => acc + item?.total_sessions, 0)
                          .toLocaleString()}{" "}
                      sessions ready for payment total of $
                      {readySessions?.length &&
                        readySessions
                          .reduce((acc, item) => acc + item?.total_amount, 0)
                          .toLocaleString()}
                    </strong>
                  </h5>
                  <p className="mb-0 font-weight-bold px-2">
                    <strong>These are sessions ready for payment</strong>
                  </p>
                </div>
                <i
                  className={`fa ${
                    isOpen ? "fa-chevron-up" : "fa-chevron-down"
                  } mb-3`}
                  onClick={handleToggle}
                ></i>
              </div>
            )}
            <div
              id="collapseOne"
              class="accordion-collapse collapse table-responsive"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              {readySessions &&
                readySessions.length > 0 &&
                readySessions.map((item, index) => (
                  <div
                    key={index}
                    className="box d-flex flex-column p-3 mb-3 rounded"
                  >
                    <div
                      className="d-flex justify-content-between"
                      aria-expanded={openIndex === index}
                      aria-controls={`collapse${index}`}
                      onClick={() => toggleAccordion(index)}
                    >
                      <div>
                        <p>
                          <strong>{item?.child_name} : </strong>
                          <strong>${item?.total_amount}</strong>
                        </p>
                        <p>
                          {item?.total_sessions}{" "}
                          {item?.total_sessions === 1 ? "Session" : "Sessions"},{" "}
                          {item?.total_time}{" "}
                          {item?.total_time <= 1 ? "Hour" : "Hours"}
                        </p>
                      </div>

                      <div>
                        <i
                          className={`fa ${
                            openIndex === index
                              ? "fa-minus-circle"
                              : "fa-plus-circle"
                          }`}
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => toggleAccordion(index)}
                        ></i>
                      </div>
                    </div>
                    <div
                      id={`collapse${index}`}
                      className={`accordion-collapse collapse ${
                        openIndex === index ? "show" : ""
                      } table-responsive`}
                      aria-labelledby={`heading${index}`}
                      data-bs-parent="#accordionExample"
                      style={{ maxHeight: "400px", overflowY: "scroll" }}
                    >
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Service</th>
                            <th>Hours</th>
                            <th>Rate</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item?.sessions.map((s, sessionIndex) => (
                            <tr key={sessionIndex}>
                              <td>{s?.service_completed}</td>
                              <td>{s?.service_name}</td>
                              <td>{s?.session_time}</td>
                              <td>${s?.pay_rate}</td>
                              <td>${s?.total_amount}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))}
            </div>

            {notReadySessons && notReadySessons.length > 0 && (
              <div
                className="d-flex justify-content-between mb-4 p-4"
                style={{
                  boxShadow:
                    "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
                  borderRadius: "5px",
                  background: "#0A1E4F",
                  color: "white",
                }}
                aria-hidden="true"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
                onClick={handleToggleNext}
              >
                <div>
                  <h5 className="mb-0 font-weight-bold px-2 mb-3">
                    <strong>
                      {notReadySessons?.length &&
                        notReadySessons
                          .reduce((acc, item) => acc + item?.total_sessions, 0)
                          .toLocaleString()}{" "}
                      sessions not ready for payment total of $
                      {notReadySessons?.length &&
                        notReadySessons
                          .reduce((acc, item) => acc + item?.total_amount, 0)
                          .toLocaleString()}
                    </strong>
                  </h5>
                  <p className="mb-0 font-weight-bold px-2">
                    <strong>These are sessions not ready for payment</strong>
                  </p>
                </div>
                <i
                  className={`fa ${
                    isOpenNext ? "fa-chevron-up" : "fa-chevron-down"
                  } mb-3`}
                  onClick={handleToggleNext}
                ></i>
              </div>
            )}

            <div
              id="collapseTwo"
              class="accordion-collapse collapse table-responsive"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              {notReadySessons &&
                notReadySessons.length > 0 &&
                notReadySessons.map((item, index) => (
                  <div
                    key={index}
                    className="box d-flex flex-column p-3 mb-3 rounded"
                  >
                    <div
                      className="d-flex justify-content-between"
                      aria-expanded={openIndexNext === index}
                      aria-controls={`collapse${index}`}
                      onClick={() => toggleAccordionNext(index)}
                    >
                      <div>
                        <p>
                          <strong>{item?.child_name} : </strong>
                          <strong>${item?.total_amount}</strong>
                        </p>
                        <p>
                          {item?.total_sessions}{" "}
                          {item?.total_sessions === 1 ? "Session" : "Sessions"},{" "}
                          {item?.total_time}{" "}
                          {item?.total_time <= 1 ? "Hour" : "Hours"}
                        </p>
                      </div>
                      <div className="">
                        <i
                          className={`fa ${
                            openIndexNext === index
                              ? "fa-minus-circle"
                              : "fa-plus-circle"
                          }`}
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => toggleAccordionNext(index)}
                        ></i>
                      </div>
                    </div>
                    <div
                      id={`collapse${index}`}
                      className={`accordion-collapse collapse ${
                        openIndexNext === index ? "show" : ""
                      } table-responsive`}
                      aria-labelledby={`heading${index}`}
                      data-bs-parent="#accordionExample"
                      style={{ maxHeight: "400px", overflowY: "scroll" }}
                    >
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Service</th>
                            <th>Hours</th>
                            <th>Rate</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item?.sessions.map((s, sessionIndex) => (
                            <tr key={sessionIndex}>
                              <td>{s?.service_completed}</td>
                              <td>{s?.service_name}</td>
                              <td>{s?.session_time}</td>
                              <td>${s?.pay_rate}</td>
                              <td>${s?.total_amount}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))}
            </div>

            {/* <div className="box d-flex justify-content-between p-3 mb-3 rounded">
              <div>
                <h5>
                  <strong>$6,578 paid for 71 sessions</strong>
                </h5>
              </div>
              <div className="">
                <i className="fa-solid fa-arrow-right"></i>
              </div>
            </div>

            <div className="box d-flex justify-content-between p-3 mb-3 rounded">
              <div>
                <h5>
                  <strong>$6,578 paid for 71 sessions</strong>
                </h5>
              </div>
              <div className="">
                <i className="fa-solid fa-arrow-right"></i>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
}

export default UnpaidSessions;
