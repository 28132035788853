import React, { useEffect, useState, useRef } from "react";
import Header from "./Header";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { Cookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APIEndpoints } from "./APIEndpoints";
import { useNavigate } from "react-router-dom";
import { removeLocalStorageItem } from "./localStorageUtil";
import SignatureCanvas from "react-signature-canvas";

function BillableDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const cookie = new Cookies();
  const token = cookie.get("logindata");

  const yearMonth = location?.state?.yearMonth;
  const serviceId = location?.state?.serviceId;
  const serviceName = location?.state?.serviceName;

  const [apidata, setApiData] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleBoxClick = (serviceName, yearMonth, serviceId) => {
    const cookies = new Cookies();
    const expiration = new Date(new Date().getTime() + 5 * 60 * 1000); // 5 minutes from now
    const clickedTasks = cookies.get("clickedTasks") || [];

    const newClickedTask = { serviceName, yearMonth, serviceId };
    const updatedClickedTasks = [...clickedTasks, newClickedTask];

    cookies.set("clickedTasks", updatedClickedTasks, {
      path: "/",
      expires: expiration,
    });
  };

  // const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!yearMonth || !serviceId || !serviceName) {
          navigate("/");
          return;
        }

        setIsLoading(true);
        const { sf_id } = token;
        const response = await axios(
          APIEndpoints.SESSION_VERIFICATION +
            `${sf_id}/${serviceId}/${yearMonth}/`,
          {
            method: "POST",
          }
        );
        setApiData(response.data);
        setIsLoading(false);
        // if (response.data.length <= 4){
        //     setShowDiv(true);
        // }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // LOGIC FOR SIGNATURE PAD

  const [signatureData, setSignatureData] = useState(null);

  const [file, setFile] = useState();

  const signatureCanvasRef = useRef(null);

  const base64ToFile = (base64, filename) => {
    const arr = base64.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const clearSignature = () => {
    signatureCanvasRef.current.clear();
    setSignatureData(null);
    setFile(null);
  };

  const saveSignature = () => {
    const signatureImage = signatureCanvasRef.current.toDataURL();
    setSignatureData(signatureImage);

    const file = base64ToFile(signatureImage, "signature.png");
    setFile(file);
  };

  const handleSignUpload = async () => {
    try {
      if (!yearMonth || !serviceId || !serviceName) {
        navigate("/");
        return;
      }

      if (!file) {
        toast.error("Please upload signature first,", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }

      setLoading(true);

      const formData = new FormData();
      const { access_token, sf_id } = token;
      formData.append("photo", file);

      // Make the POST request to the API
      const response = await fetch(
        APIEndpoints.BILLABLE_FORM_SUBMIT +
          `${sf_id}/${serviceId}/${yearMonth}/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
          body: formData,
        }
      );

      if (response.status === 200) {
        // Handle the response from the API
        const data = await response.json();

        handleBoxClick(serviceName, yearMonth, serviceId);

        setLoading(false);
        toast.success("Successfully submitted. We will email shortly.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });

        // Delete some things from local storage
        removeLocalStorageItem("billableData", 5);
      } else if (response.status === 204) {
        setLoading(false);
        toast.error("No service.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      } else {
        setLoading(false);
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }

      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      setLoading(false);
      toast.error("Error in Sign Upload", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("Error in Sign Upload.");
    }
  };

  return (
    <>
      <Header />
      <ToastContainer style={{ top: "7rem", position: "fixed" }} />
      {isloading ? (
        <div className="container-fluid custom-container my-5">
          <div className="container-fluid my-5 h-100">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid custom-container my-4">
          <div className="container-fluid my-5 h-100">
            <div
              className="container card"
              style={{ background: "transparent" }}
            >
              <div className="row p-2 text-center ">
                <h4>{serviceName}</h4>
              </div>
              <hr className="m-0" />
              <div
                className="row billable-detail-row"
                style={{ marginTop: "20px", marginLeft: "25px" }}
              >
                <div className="mb-3 row">
                  <div className="table-responsive">
                    <table
                      className="table table-striped table-hover"
                      style={{ marginLeft: "12px" }}
                    >
                      <thead>
                        <tr>
                          <th>Location</th>
                          <th scope="col">Service Location</th>
                          <th scope="col">Service Status</th>
                          <th scope="col">Service Type</th>
                          <th scope="col">Completed Date</th>
                          <th scope="col">Start Time</th>
                          <th scope="col">End Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {apidata.map((entry, index) => (
                          <tr key={index}>
                            <td>{entry.location}</td>
                            <td>{entry.service_location}</td>
                            <td>{entry.service_status}</td>
                            <td>{entry.service_type}</td>
                            <td>{entry.completed_date}</td>
                            <td>{entry.start_time}</td>
                            <td>{entry.end_time}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <h6>
                  <b>
                    I certify that the above services were provided in
                    accordance with the child’s IFSP.
                  </b>
                </h6>
              </div>
              <div className="row">
                <div
                  className="mb-3"
                  style={{
                    background: "rgb(248, 248, 248)",
                    padding: "20px",
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      textAlign: "center",
                    }}
                  >
                    <a
                      className="position-absolute signature-erase signature-erase-billable"
                      onClick={clearSignature}
                    >
                      <i className="fa-solid fa-eraser"></i>
                    </a>
                    <SignatureCanvas
                      ref={signatureCanvasRef}
                      penColor="black"
                      canvasProps={{
                        width: 500,
                        height: 200,
                        className: "signatureCanvas",
                      }}
                      onEnd={saveSignature}
                    />

                    {signatureData && (
                      <div className="text-center mt-3">
                        <img
                          src={signatureData}
                          style={{
                            width: "30%",
                            border: "1px solid black",
                            background: "white",
                          }}
                          alt="signature Image"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row text-center">
                <div className="col my-2">
                  <Button
                    className="btn btn-dark text-white px-4"
                    onClick={() => {
                      handleSignUpload();
                    }}
                  >
                    {loading ? (
                      <span>
                        Submiting...{" "}
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        ></div>
                      </span>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BillableDetail;
