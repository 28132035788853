import React from 'react'

function ThankYou() {
  return (
    <div className="container h-100 d-flex justify-content-center align-items-center ">
      <div className="box p-4 my-4">
        <h1>Your signature has been submitted.</h1>
      </div>
    </div>
  );
}

export default ThankYou
