import React from 'react'
import Header from './Header';

function AvailabilityAreas() {
    return (
        <>
            <Header />
            <div className="container custom-container my-5">
                <div className='p-3'>
                    <p className='fw-semibold'>Select the area you are willing to service below</p>
                </div>
                <div className='p-3'>
                    <div className="row justify-content-between my-3">
                        <div className="col"><p>List Item</p></div>
                        <div className="col text-end"><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked /></div>
                    </div>

                    <div className="row justify-content-between my-3">
                        <div className="col"><p>List Item</p></div>
                        <div className="col text-end"><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked /></div>
                    </div>

                    <div className="row justify-content-between my-3">
                        <div className="col"><p>List Item</p></div>
                        <div className="col text-end"><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked /></div>
                    </div>

                    <div className="row justify-content-between my-3">
                        <div className="col"><p>List Item</p></div>
                        <div className="col text-end"><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked /></div>
                    </div>

                    <div className="row justify-content-between my-3">
                        <div className="col"><p>List Item</p></div>
                        <div className="col text-end"><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked /></div>
                    </div>

                    <div className="row justify-content-between my-3">
                        <div className="col"><p>List Item</p></div>
                        <div className="col text-end"><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked /></div>
                    </div>

                    <div className="row justify-content-between my-3">
                        <div className="col"><p>List Item</p></div>
                        <div className="col text-end"><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked /></div>
                    </div>

                    <div className="row justify-content-between my-3">
                        <div className="col"><p>List Item</p></div>
                        <div className="col text-end"><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked /></div>
                    </div>

                    <div className='box d-flex flex-column p-3 mb-3 my-2 rounded'>
                        <div>
                            <p className='ms-2'>Save your changes</p>
                        </div>
                        <div className=' row my-auto d-flex flex-row justify-content-between'>
                            <div className="col">
                                <button type="button" className="btn btn-discard">Discard</button>
                            </div>
                            <div className="col">
                                <button type="button" className="btn btn-save">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AvailabilityAreas
