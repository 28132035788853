import React, { useState, useRef, useEffect } from "react";
import Header from "./Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { APIEndpoints } from "./APIEndpoints";
import { useNavigate, useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";

const checkList = [
  "Individualized Family Service Plan (IFSP)",
  "Outcomes & Service Recommendations",
  "Evaluation/Assessment Reports",
  "Session Clinical Notes & Progress Summaries",
  "Medical Reports",
  "Discussion of Strategies/Ongoing Activities",
  "Discussion of Diagnosis/Medical Condition",
  "Discussion of Transition Plans",
];

function ConsentSignature() {
  const { childId, childContactId, therapistId } = useParams();
  const navigate = useNavigate();
  const [showDiv, setShowDiv] = useState(false);
  const [formsubmitting, setFormSubmitting] = useState(false);

  const [signatureData, setSignatureData] = useState(null);

  useEffect(() => {
    if (!childId || !childContactId || !therapistId) {
      navigate("/");
    }
  }, [childId, childContactId, therapistId, navigate]);

  const signatureCanvasRef = useRef(null);

  const clearSignature = () => {
    signatureCanvasRef.current.clear();
    setSignatureData(null);
  };

  const saveSignature = () => {
    const signatureImage = signatureCanvasRef.current.toDataURL();
    setSignatureData(signatureImage);
  };

  const divRef = useRef();
  const showRef = useRef();

  const myInputStyle = {
    // border: "0",
    // outline: "0",
    // background: "transparent",
    // border: "1px solid #212020",
    // borderRadius: "0",
    width: "55%",
  };

  const myBigInputStyle = {
    // border: "0",
    // outline: "0",
    // background: "transparent",
    // border: "1px solid #212020",
    // borderRadius: "0",
  };

  const mySmallStyle = {
    // border: "0",
    // outline: "0",
    // background: "transparent",
    // border: "1px solid #212020",
    // borderRadius: "0",
    width: "40%",
  };

  const [formData, setFormData] = useState({
    inform1: "",
    inform2: "",
    inform3: "",
    inform4: "",
    release: "",
    verbal: "",
    both: "",
    representativeName: "",
  });

  const [checkboxes, setCheckboxes] = useState({
    familyService: "",
    outcomeRecommendation: "",
    evuationReports: "",
    progressSummaries: "",
    medicalReports: "",
    ongoingActivities: "",
    medicalConditions: "",
    transitionPlans: "",
  });

  const handleCheckboxChange = (e) => {
    const { name, value } = e.target;
    setCheckboxes({
      ...checkboxes,
      [name]: value,
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [Intervention_service, setIntervention_service] = useState("");

  // Function to handle checkbox change
  const handleCheckboxChange1 = (event) => {
    setIntervention_service(event.target.value);
    console.log(event.target.value);
  };

  const [expireDate, setExpireDate] = useState("");
  const [therapyDate, setTherapyDate] = useState("");

  const handleExpireDateChange = (event) => {
    setExpireDate(event.target.value);
  };

  const handleTherapyDateChange = (event) => {
    setTherapyDate(event.target.value);
  };

  const [informationReleased, setInformationReleaed] = useState("");
  const handleInformationReleased = (event) => {
    setInformationReleaed(event.target.value);
  };

  const submitData = async (e) => {
    e.preventDefault();

    if (signatureData === null) {
      toast.error("Please upload the signature", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setFormSubmitting(true);
    try {
      const payload = {
        childId: childId,
        therapistId: therapistId,
        contactId: childContactId,
        inform1: formData.inform1,
        inform2: formData.inform2,
        inform3: formData.inform3,
        inform4: formData.inform4,
        release: formData.release,
        informationReleased: informationReleased,
        familyService: checkboxes.familyService,
        outcomeRecommendation: checkboxes.outcomeRecommendation,
        evuationReports: checkboxes.evuationReports,
        progressSummaries: checkboxes.progressSummaries,
        medicalReports: checkboxes.medicalReports,
        ongoingActivities: checkboxes.ongoingActivities,
        medicalConditions: checkboxes.medicalConditions,
        transitionPlans: checkboxes.transitionPlans,
        Intervention_service: Intervention_service,
        expireDate: expireDate,
        therapyDate: therapyDate,
        representativeName: formData.representativeName,
        signature: signatureData,
      };
      const response = await axios.post(
        `${APIEndpoints.CONSENT_FORM}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setFormSubmitting(false);
      const data = response.data;
      if (response.status === 200) {
        const response_msg = data?.message;
        toast.success(response_msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          navigate("/thank-you");
        }, 2000);
      } else {
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err) {
      console.log("Error in submitForm ", err);
      toast.error("Something went wrong! Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFormSubmitting(false);
    }
  };
  return (
    <>
      <Header />
      <ToastContainer />
      <form onSubmit={submitData} className="pb-4">
        <div className="container custom-container my-5">
          <div className="pb-5">
            <div>
              <h5 className="font-weight-bold">Therapy Associates, LLC</h5>
            </div>
            <hr />
            <p className="fs-6">
              1051 Bloomfield Ave., Suite 15
              <br />
              Clifton, NJ 07012
              <br />
              (973) 288-9963
            </p>
            <div className="d-flex justify-content-center mt-4">
              <h4>
                <u className="fw-bolder">CONSENT TO RELEASE INFORMATION</u>
              </h4>
            </div>
            <div className="d-grid mt-4">
              <p>
                Therapy Associates, LLC is an agency licensed to provide direct
                Early Intervention services by the State of New Jersey, as
                coordinated by the Special Child Health Services in each county.
                Therapy Associates must have your consent to discuss any
                confidential information with anyone that is not a parent or
                legal representative. Your consent is being requested to obtain,
                share and discuss information about your child with the CP&P.
                You may choose not to give consent, to give consent to share
                information for some items but not others, or to give consent to
                share information on all items. If you choose to give consent,
                you may retract some or all of it at any time, with written
                notification to our office.
              </p>
            </div>
            {/*<div className="d-flex mt-4">
              <div className="d-flex justify-content-center align-items-center">
                <p className="fw-bolder" style={{ margin: "1rem 1rem 0 0" }}>
                  Child's name:
                </p>
                <input
                  type="text"
                  className=""
                  style={myInputStyle}
                  name="childName"
                  value={formData.childName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="d-flex justify-content-center align-items-center ms-3">
                <p className="fw-bolder" style={{ margin: "1rem 1rem 0 0" }}>
                  Therapist's name:
                </p>
                <input
                  type="text"
                  className="w-25"
                  style={myInputStyle}
                  name="therapistName"
                  value={formData.therapistName}
                  onChange={handleInputChange}
                />
              </div>
            </div>*/}
            <div className="mt-4">
              <p className="fw-bold">
                I give my informed consent to the Therapy Associates
                practitioners to release information to:
              </p>
            </div>
            <div className="mt-4">
              <div className="d-flex flex-wrap gap-2 fs-6">
                <div className="d-flex justify-content-center align-items-center me-4">
                  1)
                  <input
                    type="text"
                    className="form-control ms-2"
                    style={myBigInputStyle}
                    name="inform1"
                    value={formData.inform1}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  2)
                  <input
                    type="text"
                    className="form-control ms-2"
                    style={myBigInputStyle}
                    name="inform2"
                    value={formData.inform2}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <br />
              <div className="d-flex gap-2 flex-wrap">
                <div className="d-flex  justify-content-center align-items-center me-4">
                  3)
                  <input
                    type="text"
                    className="form-control ms-2"
                    style={myBigInputStyle}
                    name="inform3"
                    value={formData.inform3}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  4)
                  <input
                    type="text"
                    className="form-control ms-2"
                    style={myBigInputStyle}
                    name="inform4"
                    value={formData.inform4}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="">
                <p style={{ margin: "1rem 1rem 0 0" }}>
                  Information that may be released:
                </p>
                <div className="d-flex flex-wrap gap-1">
                  <div className="d-flex mt-2">
                    <p className="ms-2">Written</p>
                    <input
                      className="ms-2 form-check-input"
                      type="radio"
                      id="information_released1"
                      name="information_released"
                      value="written"
                      onChange={handleInformationReleased}
                    />
                  </div>
                  <div className="d-flex mt-2">
                    <p className="ms-2">Verbal</p>
                    <input
                      className="form-check-input ms-2"
                      type="radio"
                      id="information_released2"
                      name="information_released"
                      value="verbal"
                      onChange={handleInformationReleased}
                    />
                  </div>
                  <div className="d-flex mt-2">
                    <p className="ms-2">Both</p>
                    <input
                      className="form-check-input ms-2"
                      type="radio"
                      id="information_released3"
                      name="information_released"
                      value="both"
                      onChange={handleInformationReleased}
                    />
                  </div>
                </div>
                <br />
              </div>
            </div>

            <div>
              <p className="fw-bolder ms-5 mt-4">
                Please check all areas yes or no:
              </p>
              {Object.entries(checkboxes).map(([key, checked], index) => (
                <div className="d-flex flex-wrap mt-1" key={key}>
                  <p className="me-3">{checkList[index]}</p>
                  <div className="d-flex">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={key}
                        id={`${key}1`}
                        // checked={checked}
                        value={"yes"}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor={`${key}1`}>
                        Yes
                      </label>
                    </div>
                    <div className="form-check ms-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={key}
                        id={`${key}2`}
                        value={"no"}
                        // checked={!checked}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor={`${key}2`}>
                        No
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <p className="mt-4">
              I give my informed consent to have the above-named caregiver(s)
              sign the Service Encounter Verification Form in my place and to
              have them observe the Early Intervention service session.
            </p>
            <div className="d-flex">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="intervention_System1"
                  name="intervention_System"
                  value="yes"
                  onChange={handleCheckboxChange1}
                />
                <label
                  className="form-check-label"
                  htmlFor="intervention_System1"
                >
                  Yes
                </label>
              </div>
              <div className="form-check ms-4">
                <input
                  className="form-check-input"
                  type="radio"
                  id="intervention_System2"
                  name="intervention_System"
                  value="no"
                  onChange={handleCheckboxChange1}
                />
                <label
                  className="form-check-label"
                  htmlFor="intervention_System2"
                >
                  No
                </label>
              </div>
            </div>
            <p className="mt-4">
              This record will be part of the child’s early intervention file.
              This record is subject to Federal Educational Rights and Privacy
              Act (FERPA). This record is available for my review and may be
              reproduced and corrected at my request. I understand that I may
              change or withdraw this release of information/consent at any
              time.
            </p>
            <div className="d-flex flex-wrap">
              <p style={{ margin: "1rem 1rem 0 0" }}>
                This authorization shall expire on:
              </p>
              <div>
                <input
                  type="date"
                  className="expire-date-input ms-2 form-control"
                  style={myBigInputStyle}
                  value={expireDate}
                  onChange={handleExpireDateChange}
                />
                <p>Date: (not to exceed child’s 3rd birthday)</p>
              </div>
            </div>
            <div className="d-flex flex-wrap">
              <p style={{ margin: "1rem 1rem 0 0" }}>
                Printed name of parent/legal representative:
              </p>
              <input
                type="text"
                className="form-control ms-2"
                style={mySmallStyle}
                name="representativeName"
                value={formData.childName}
                onChange={handleInputChange}
              />
            </div>

            <hr />
            <p className="mt-3">
              I give my consent that the above information be shared with
              Therapy Associates, LLC.
            </p>
            <div className="d-flex mb-4">
              <p className="" style={{ margin: "1rem 1rem 0 0" }}>
                Date
              </p>
              <input
                type="date"
                className="therapy-date-input ms-2 form-control w-50"
                style={myBigInputStyle}
                value={therapyDate}
                onChange={handleTherapyDateChange}
              />
            </div>
            <div ref={showRef}>
              <div
                className="mt-2"
                style={{ background: "rgb(248, 248, 248)", padding: "10px" }}
                ref={divRef}
              >
                <p>Signature of parent/legal representative:</p>
                <div
                  className="mt-3"
                  style={{
                    textAlign: "center",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <SignatureCanvas
                    ref={signatureCanvasRef}
                    penColor="black"
                    canvasProps={{
                      width: 500,
                      height: 200,
                      className: "signatureCanvas",
                    }}
                    onEnd={saveSignature}
                  />
                  <a
                    className="position-absolute signature-erase signature-erase-consent "
                    onClick={clearSignature}
                  >
                    <i className="fa-solid fa-eraser"></i>
                  </a>
                </div>
                {signatureData && (
                  <div className="text-center mt-3">
                    <img
                      src={signatureData}
                      style={{
                        width: "30%",
                        border: "1px solid black",
                        background: "white",
                      }}
                      alt="signature Image"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="text-center">
            <button type="submit" className="btn btn-dark">
              {formsubmitting ? (
                <span>
                  Submitting{" "}
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  ></div>
                </span>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default ConsentSignature;
